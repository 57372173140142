import { Button, ButtonProps } from '@mui/material'
import React from 'react'

export const QuantityControlButton: React.FC<ButtonProps> = ({
    children,
    onClick,
}) => {
    return (
        <Button
            sx={{
                p: 0,
                width: 24,
                height: 24,
                minWidth: 24,
                fontWeight: 100,
                borderRadius: '4px',
                color: '#000000',
                bgcolor: '#F5F3F3',

                '&:hover': {
                    bgcolor: '#F5F3F3',
                },
            }}
            variant="contained"
            onClick={onClick}
        >
            {children}
        </Button>
    )
}

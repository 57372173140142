import { Box, Button, Drawer, Link, Typography } from '@mui/material'
import React, { useState } from 'react'

export const MobileCookieInfoDialog: React.FC = () => {
    const [open, setOpen] = useState<boolean>(true)

    const handleClose = () => {
        setOpen(false)
        localStorage.setItem('cookie_confirmed', 'true')
    }

    return (
        <Drawer
            open={open}
            anchor={'bottom'}
            PaperProps={{
                sx: {
                    width: '100%',
                    maxWidth: { xs: '100%' },
                    zIndex: 1,
                },
            }}
            sx={{
                zIndex: 1400,
            }}
        >
            <Box
                sx={{
                    pb: 6.25,
                    px: 2,
                    width: {
                        md: '550px',
                    },
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <Typography mt={1.5} variant="body3">
                    Посещая наш веб-сайт, Вы соглашаетесь на обработку cookies,
                    обработка которых осуществляется в соответствии с{' '}
                    <Link
                        rel="noreferrer"
                        href="/personal-data-policy.pdf"
                        target="_blank"
                    >
                        Политикой обработки персональных данных
                    </Link>
                    .
                </Typography>

                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ px: 1.75, mt: 2 }}
                    onClick={handleClose}
                >
                    <Typography variant="body2" fontWeight={500}>
                        Принять
                    </Typography>
                </Button>
            </Box>
        </Drawer>
    )
}

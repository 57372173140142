import { Box } from '@mui/material'
import {categoriesWatcher} from "app/modules/Categories/slice/saga"
import {selectHeaderCategories} from "app/modules/Categories/slice/selectors"
import {useCategoryPageContext} from "app/modules/Products/components/ProductsFilter/hooks"
import {
    ProductsFilterSubCategory
} from "app/modules/Products/components/ProductsFilter/ProductsFiltersList/components/ProductsFilterSubCategory"
import React from 'react'
import {useSelector} from "react-redux"

import { ProductsFilterButton } from '../ProductsFilterButton'
import {
    ProductsFilterOnlyInStock,
    ProductsFilterOnlySale,
    ProductsFilterOptions,
    ProductsFilterPrice,
    ProductsFilterProperties,
    ProductsFilterSize,
    ProductsFilterCategory,
} from './components'

export const ProductsFiltersList: React.FC = () => {

    const {filterReadAdapter, handleClearFilter, category, handleChangeFilter, isSaleCategory, handleTaxonsChange} =
      useCategoryPageContext()

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
            }}
        >
            <ProductsFilterCategory />

            {filterReadAdapter?.taxons?.map(category => (
              <ProductsFilterSubCategory category={category} key={category}/>
            ))}

            <ProductsFilterOnlyInStock />

            <ProductsFilterSize />

            <ProductsFilterPrice />

            <ProductsFilterOptions />

            <ProductsFilterProperties />

            <ProductsFilterOnlySale />

            <ProductsFilterButton />
        </Box>
    )
}

import { ProductDetail } from 'app/modules/Products/components/ProductDetail'
import { ProductMobileVisualContent } from 'app/modules/Products/components/ProductVisualContent'
import React from 'react'

import { ProductProps } from '../Product'

export const MobileProduct: React.FC<ProductProps> = (props) => {
    return (
        <>
            <ProductMobileVisualContent {...props} />

            <ProductDetail {...props} />
        </>
    )
}

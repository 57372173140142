import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/material'
import { testPhoneNumber } from 'app/components/PhoneField/utils/validatePhoneNumber'
import { checkoutActions } from 'app/modules/Checkout/slice'
import { selectCustomerInformation } from 'app/modules/Checkout/slice/selectors'
import { selectImageById } from 'app/modules/Images/slice/selectors'
import { FormikProps, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { DaDataAddress, DaDataSuggestion } from 'react-dadata'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { EStatus } from 'types'
import {
    ICustomerInformation,
    ShippingMethodType,
} from 'types/ICustomerInformation'
import { IShop } from 'types/IShop'
import { flattenObject } from 'utils/flattenObject'
import {
    googleAnalyticsEvent,
    googleAnalyticsEvents,
} from 'utils/googleAnalytics'
import * as yup from 'yup'

import { cartActions } from '../../slice'
import {
    selectCartDisabledDeliveryStates,
    selectCartShop,
} from '../../slice/selectors'
import { CartBackButton } from '../CartBackButton'
import { CartNavigationsButtons } from '../CartNavigationsButtons'
import { CustomerData } from './CustomerData'
import { DeliveryStateForm } from './DeliveryStateForm'

export interface CustomerInformationChildProps {
    formik: FormikProps<ICustomerInformation>
    shop: IShop | null
    shopImage: string
    daDataSuggestionAddress: DaDataSuggestion<DaDataAddress> | undefined
    setDaDataSuggestionAddress: (
        value: DaDataSuggestion<DaDataAddress> | undefined
    ) => void
    handleShippingMethodIdChange: (newValue: ShippingMethodType) => void
    setIsAddressChanged: (value: boolean) => void
}

export const CustomerInformationForm: React.FC = () => {
    const dispatch = useDispatch()

    const [isLoading, setLoading] = useState<boolean>(false)
    const [submitUpdater, updateSubmit] = useState(false)
    const [daDataSuggestionAddress, setDaDataSuggestionAddress] =
        useState<DaDataSuggestion<DaDataAddress>>()
    const [isAddressChanged, setIsAddressChanged] = useState<boolean>(false)

    const { data, status } = useSelector(selectCustomerInformation)
    const getImage = useSelector(selectImageById)
    const shop = useSelector(selectCartShop)
    const disabledDeliveryStates = useSelector(selectCartDisabledDeliveryStates)
    const shopImage = getImage(shop?.relationships.image.data?.id)

    const validationSchema = yup.object({
        order: yup.object({
            email: yup
                .string()
                .nullable()
                .required('Email - обязатальное поле')
                .email('Не корректный Email'),
            delivery_state: yup
                .string()
                .nullable()
                .oneOf(Object.values(ShippingMethodType))
                .required('Не выбран способ получения'),
            special_instructions: yup.string().nullable(),
            ship_address_attributes: yup.object({
                firstname: yup
                    .string()
                    .nullable()
                    .required('Имя - обязатальное поле'),
                lastname: yup
                    .string()
                    .nullable()
                    .required('Фамилия - обязатальное поле'),
                address1: yup
                    .string()
                    .nullable()
                    .required('Адрес - обязательное поле')
                    .test(
                        'Courier addres check',
                        'Не введен адрес дома',
                        testAddress1
                    ),
                city: yup
                    .string()
                    .nullable()
                    .required('Адрес - обязательное поле'),
                phone: yup
                    .string()
                    .nullable()
                    .required('Номер телефона - обязатальное поле')
                    .test(
                        'Phone number check',
                        'Некорректный номер телефона',
                        testPhoneNumber
                    ),
                zipcode: yup.string().nullable(),
                // .required('Невозможно определить почтовый индекс'),
            }),
        }),
    })

    const formik = useFormik({
        validationSchema,
        initialValues: data,
        validateOnBlur: false,
        validateOnChange: false,
        enableReinitialize: true,
        onSubmit: (values) => {
            googleAnalyticsEvent(googleAnalyticsEvents.address_entry)
            setLoading(true)

            dispatch(checkoutActions.updateCustomerInformation(values))
        },
    })

    useEffect(() => {
        if (status === EStatus.ERROR) {
            setLoading(false)
            dispatch(checkoutActions.cleanCustomerInformationStatus())
        }
    }, [status])

    useEffect(() => {
        dispatch(checkoutActions.cleanCustomerInformationStatus())
    }, [])

    useEffect(() => {
        // Если не проверить, то значение address1 в initialValues будет затерто и не отобразится на форме
        if (
            daDataSuggestionAddress &&
            formik.values.order.delivery_state === ShippingMethodType.courier
        ) {
            formik.setFieldValue(
                'order.ship_address_attributes.address1',
                daDataSuggestionAddress.value
            )
            formik.setFieldValue(
                'order.ship_address_attributes.city',
                daDataSuggestionAddress.data.city ||
                    daDataSuggestionAddress.data.settlement
            )
            formik.setFieldValue(
                'order.ship_address_attributes.zipcode',
                daDataSuggestionAddress.data.postal_code
            )
        }
    }, [daDataSuggestionAddress])

    const handleShippingMethodIdChange = (
        newValue: ShippingMethodType,
        resetCourier = true
    ) => {
        if (disabledDeliveryStates.includes(newValue)) return

        formik.setFieldValue('order.delivery_state', newValue)
        localStorage.setItem('shippingMethodId', newValue.toString())

        if (newValue === ShippingMethodType.courier && resetCourier) {
            formik.setFieldValue('order.ship_address_attributes.address1', '')
            formik.setFieldValue('order.ship_address_attributes.zipcode', '')
        }
    }

    useEffect(
        function LoadShopInformation() {
            if (
                shop &&
                formik.values.order.delivery_state === ShippingMethodType.pickup
            ) {
                formik.setFieldValue(
                    'order.ship_address_attributes.address1',
                    shop.attributes.address
                )
                formik.setFieldValue(
                    'order.ship_address_attributes.city',
                    shop.attributes.address.split(',').find(Boolean)
                )
                formik.setFieldValue(
                    'order.ship_address_attributes.zipcode',
                    null
                )
            }
        },
        [shop, formik.values.order.delivery_state]
    )

    useEffect(function LoadDefaultShippingMethodId() {
        if (status === EStatus.INITIAL) {
            const localStorageShippingMethodId = localStorage.getItem(
                'shippingMethodId'
            ) as ShippingMethodType
            handleShippingMethodIdChange(
                Object.values(ShippingMethodType).includes(
                    localStorageShippingMethodId
                )
                    ? localStorageShippingMethodId
                    : ShippingMethodType.courier,
                false
            )
        }
    }, [])

    function testAddress1(address1?: string | null): boolean {
        if (!address1 || isAddressChanged) return false

        // Такое бывает если только никаких изменений в адресе не было
        // В этом случае данные заведемо корректные
        if (!daDataSuggestionAddress) return true

        return (
            formik.values.order.delivery_state !== ShippingMethodType.courier ||
            Boolean(daDataSuggestionAddress.data.house)
        )
    }

    useEffect(
        function toastErrors() {
            if (formik.errors.order && !formik.isSubmitting) {
                const errorMsg = flattenObject(formik.errors.order).find(
                    Boolean
                )
                if (errorMsg) {
                    toast.error(errorMsg)
                }
            }
        },
        [formik.errors.order, submitUpdater, formik.isSubmitting]
    )

    const handlePrevious = () => {
        dispatch(cartActions.showCart())
    }

    const customerInformationProps: CustomerInformationChildProps = {
        formik,
        shop,
        shopImage,
        daDataSuggestionAddress,
        setDaDataSuggestionAddress,
        handleShippingMethodIdChange,
        setIsAddressChanged,
    }

    return (
        <Box
            noValidate
            component="form"
            autoComplete="off"
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault()
                updateSubmit((oldValue) => !oldValue)
                formik.handleSubmit(e)
            }}
            sx={{
                mt: { xs: '30px', md: '37px' },
                mb: { xs: '50px', md: '60px' },
                maxWidth: '600px',
            }}
        >
            <DeliveryStateForm {...customerInformationProps} />

            <CustomerData {...customerInformationProps} />

            <Box sx={{ mt: { xs: '40px', md: '60px' } }}>
                <CartNavigationsButtons
                    backButton={
                        <CartBackButton
                            handlePrevious={handlePrevious}
                            loading={false}
                        />
                    }
                    nextButton={
                        <LoadingButton
                            loading={isLoading}
                            type="submit"
                            variant="contained"
                            sx={{
                                width: '100%',
                                height: '100%',
                                maxWidth: { md: '222px' },
                            }}
                        >
                            ПРОДОЛЖИТЬ
                        </LoadingButton>
                    }
                />
            </Box>
        </Box>
    )
}

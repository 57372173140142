import { Relationship, ResourceObject, Response } from 'utils/json-api'

import { ICategory } from './ICategory'
import { IImage } from './IImage'
import { IMultiverse } from './IMultiverse'
import { IVariant } from './IVariant'
import { IVideo } from './IVideo'

export enum ProductSortVariants {
    CREATED_AT = 'created_at',
}

export interface ProductFilterParams {
    price?: [number | undefined, number | undefined]
    available_in_current_city?: boolean | undefined
    sale?: boolean | undefined
    taxons?: string
    options?: { [key: string]: string[] | undefined }
    properties?: { [key: string]: string | undefined }
    purchasable?: boolean
    status?: string | undefined
    ids?: string
}

export type IProductIncluded = (IImage | IVariant | IVideo)[]

export type IProductItemResponse = Response<IProduct, IProductIncluded>

export type IProductCollectionResponse = Response<IProduct[], IProductIncluded>

export const ProductTypes = ['product', 'extended_product'] as const

export interface IProductShops {
    id: string
    type: string
    attributes: {
        is_available: boolean
        count_on_hand: number
        shop_id: number
    },
    relationships: {
        stock_location: {
            data: {
                id: string
                type: string
            }
        },
        variant: {
            data: {
                id: string
                type: string
            }
        }
    }
}

export interface IProduct extends ResourceObject {
    id: string
    type: typeof ProductTypes[number]
    attributes: {
        name: string
        description: string
        price: number
        compare_at_price: number
        currency: string
        display_price: string
        display_compare_at_price: string
        available_on: string
        purchasable: boolean
        id_1c: string
        in_stock: boolean
        backorderable: boolean
        slug: string
        sku: string
        meta_description: string
        meta_keywords: string
        updated_at: string
        postcard_coords: string
        postcard_name: string
        sale_percent: number
        favorite_count: number
        favorite_for_user: boolean
        available_in_current_city: boolean
        delivery: boolean
    }
    relationships: {
        images: Relationship<IImage[]>
        videos: Relationship<IVideo[]>
        taxons: Relationship<ICategory[]>
        variants: Relationship<IVariant[]>
        default_variant: Relationship<IVariant>
        primary_variant: Relationship<IVariant>
        multiverse: Relationship<IMultiverse>
    }
}

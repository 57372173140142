import { Box, Container, Typography } from '@mui/material'
import { DESKTOP_HEADER_HEIGHT } from 'app/modules/Layout/components/Header/DesktopHeader'
import useOnScreen from 'hooks/useOnScreen'
import React, { useRef } from 'react'

import { IProductVisualContent } from '../../slice/types'
import { ProductPrice } from '../ProductDetail/components'
import { CartButtonProps } from '.'
import { AddAndLikeBlock } from './AddAndLikeBlock'

export const DesktopFloatingCartButton: React.FC<
    CartButtonProps & { productVisualContent: IProductVisualContent[] }
> = (props) => {
    const desktopAddButton = useRef()
    const desktopAddButtonVisible = useOnScreen(desktopAddButton)

    const VisualContent = props.productVisualContent.find(Boolean) || {
        mini: '',
    }
    return (
        <>
            <Box
                ref={desktopAddButton}
                sx={{
                    position: 'relative',
                    top: `-${DESKTOP_HEADER_HEIGHT + 25}px`,
                }}
            />
            <Box sx={{ height: '60px' }}>
                <AddAndLikeBlock {...props} />
            </Box>
            {!desktopAddButtonVisible && (
                <Box
                    sx={(theme) => ({
                        position: 'fixed',
                        backgroundColor: theme.palette.background.default,
                        top: {
                            md: `72px`,
                            lg: `${DESKTOP_HEADER_HEIGHT}px`,
                        },
                        height: '114px',
                        width: '100%',
                        zIndex: 1,

                        left: { md: '50%' },
                        transform: { md: 'translate(-50%, 0%)' },

                        display: 'flex',

                        boxShadow: '0px 12px 21px rgba(0, 0, 0, 0.12)',
                    })}
                >
                    <Box
                        sx={{
                            minWidth: 'calc(45px + 6.51vw + 20px + 55.46875vw)',
                            display: 'flex',
                            gap: '20px',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            pl: '45px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '20px',
                                alignItems: 'center',
                                maxWidth: '75%',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '6.51vw',
                                    height: '6.51vw',
                                    maxWidth: '84px',
                                    maxHeight: '84px',
                                    borderRadius: '16px',
                                    objectFit: 'cover',
                                }}
                                src={VisualContent.mini}
                                component="img"
                            />
                            <Typography variant="body1" fontWeight="medium">
                                {props.product.attributes.name}
                            </Typography>
                        </Box>
                        <ProductPrice {...props} comparePrice={false} />
                    </Box>
                    <Container
                        sx={{
                            pl: { md: '30px' },
                            pr: { md: '45px' },
                            my: { md: '27px' },
                        }}
                    >
                        <AddAndLikeBlock {...props} />
                    </Container>
                </Box>
            )}
        </>
    )
}

import { Skeleton, Typography } from '@mui/material'
import { BaseImageBanner } from 'app/components/BaseImageBanner'
import { selectImageById } from 'app/modules/Images/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { ICategory } from 'types/ICategory'

interface CategoryBannerProps {
    category?: ICategory
}

export const CategoryBanner: React.FC<CategoryBannerProps> = ({ category }) => {
    const getImage = useSelector(selectImageById)

    const image = getImage(category?.relationships.image.data?.id)

    return (
        <BaseImageBanner image={image} height="26.2vw" />
    )
}

import {
    selectAuthStatus,
    selectAuthTokenData,
} from 'app/modules/Auth/slice/selectors'
import { Auth } from 'app/modules/Auth/templates/Auth'
import { Cart } from 'app/modules/Cart/templates/Cart'
import { categoriesActions } from 'app/modules/Categories/slice'
import { citiesActions } from 'app/modules/Cities/slice'
import { selectCurrentCityID } from 'app/modules/Cities/slice/selectors'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { Header } from '../components/Header'
import HeaderContextProvider from '../components/Header/HeaderContextProvider'
import Navigation from '../components/Navigation'
import { IHeaderContextProps } from '../types/IHeaderContext'

interface MainLayoutProps {
    headerContextProps: IHeaderContextProps
}

export const MainLayout: React.FC<MainLayoutProps> = ({
    headerContextProps,
    children,
}) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const location = useLocation()

    const { isAuthorized } = useSelector(selectAuthStatus)
    const token = useSelector(selectAuthTokenData)
    const currentCityID = useSelector(selectCurrentCityID)

    const params = new URLSearchParams(location.search)
    const orderNumber = params.get('order_number')

    useEffect(() => {
        dispatch(categoriesActions.loadCategories())
        dispatch(citiesActions.loadCities())
    }, [token.created_at])

    useEffect(() => {
        dispatch(categoriesActions.loadCategories())
    }, [currentCityID])

    useEffect(() => {
        if (orderNumber && isAuthorized) {
            history.push('/profile')
        }
    }, [orderNumber && isAuthorized])

    return (
        <Auth>
            <React.Fragment key={currentCityID}>
                <Cart />

                <HeaderContextProvider headerContextProps={headerContextProps}>
                    <Header />

                    {children}

                    <Navigation />
                </HeaderContextProvider>
            </React.Fragment>
        </Auth>
    )
}

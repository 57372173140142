import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types'
import { IBanner } from 'types/IBanner'
import { ICategory } from 'types/ICategory'

import { bannersAdapter } from '.'

const { selectById } = bannersAdapter.getSelectors()

const selectDomain = (state: RootState) => state.banners

export const selectBannerById = createSelector(
    [selectDomain],
    (state) => (id: string) => selectById(state, id)
)

export const selectMainBanners = createSelector(
    [selectDomain],
    (state) =>
        state.mainBannerIDs
            .map((mainBannerID) => selectById(state, mainBannerID))
            .filter(Boolean) as IBanner[]
)

export const selectTaxonBanners = createSelector(
    [selectDomain],
    (state) => (category: ICategory) =>
        (state.taxonBannersIDs[category.id] || [])
            .map((taxonBannersID) => selectById(state, taxonBannersID))
            .filter(Boolean) as IBanner[]
)

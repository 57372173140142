import { Collapse, Container, Typography } from '@mui/material'
import { MobileMenuItem } from 'app/components/MobileMenuItem'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as MinusIcon } from '../../assets/minus_icon.svg'
import { ReactComponent as PlusIcon } from '../../assets/plus_icon.svg'

export const MobileExperienceISBlock: React.FC = () => {
    const [isOpen, setOpen] = useState<boolean>(false)

    return (
        <Container
            onClick={() => setOpen(!isOpen)}
            sx={{
                py: 0.625,
            }}
        >
            <MobileMenuItem
                text={'Island Soul Experience'}
                endContent={isOpen ? <MinusIcon /> : <PlusIcon />}
            />

            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Link to={'/static/delivery'}>
                    <Typography variant="body2" sx={{ py: 1.25 }}>
                        Доставка и оплата
                    </Typography>
                </Link>

                <Link to={'/static/dolyame'}>
                    <Typography variant="body2" sx={{ py: 1.25 }}>
                        Долями
                    </Typography>
                </Link>

                <Link to={'/static/service_department_clients'}>
                    <Typography variant="body2" sx={{ py: 1.25 }}>
                        Отдел обслуживания клиентов
                    </Typography>
                </Link>

                <Link to={'/static/ring_size'}>
                    <Typography variant="body2" sx={{ py: 1.25 }}>
                        Размеры колец
                    </Typography>
                </Link>

                <Link to={'/static/return_exchange'}>
                    <Typography variant="body2" sx={{ py: 1.25 }}>
                        Возврат и обмен
                    </Typography>
                </Link>
            </Collapse>
        </Container>
    )
}

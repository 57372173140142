import { Box, Divider } from '@mui/material'
import { ProductDetail } from 'app/modules/Products/components/ProductDetail'
import { ProductDesktopVisualContent } from 'app/modules/Products/components/ProductVisualContent'
import React from 'react'

import { ProductProps } from '../Product'

export const DesktopProduct: React.FC<ProductProps> = (props) => {
    return (
        <>
            <Divider sx={{ mt: '6px', mb: '40px' }}></Divider>
            <Box sx={{ display: 'flex' }}>
                <Box
                    sx={{
                        height: '47.34375vw',
                    }}
                >
                    <ProductDesktopVisualContent {...props} />
                </Box>

                <Box
                    sx={{
                        flexGrow: 1,
                        pb: '24px',
                    }}
                >
                    <ProductDetail {...props} />
                </Box>
            </Box>
        </>
    )
}

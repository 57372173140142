import { Box } from '@mui/material'
import React from 'react'

import { CartButtonProps } from '.'
import { AddAndLikeBlock } from './AddAndLikeBlock'

export const MobileFloatingCartButton: React.FC<CartButtonProps> = (props) => {
    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: { xs: 55, md: 'unset' },
                width: '100%',
                height: '64px',
                zIndex: 5,
                background: 'white',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',

                px: { xs: '16px' },
                py: { xs: '8px' },
            }}
        >
            <AddAndLikeBlock {...props} />
        </Box>
    )
}

import { LoadingButton } from '@mui/lab'
import { InputAdornment, TextField, Typography, useTheme } from '@mui/material'
import { checkoutActions } from 'app/modules/Checkout/slice'
import { selectLoyaltyData } from 'app/modules/Checkout/slice/selectors'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ICartProps, IControlActive } from '../types'

interface IPromocodeControl {
    controlActive: IControlActive
}

export const PromocodeControl: React.FC<IPromocodeControl & ICartProps> = ({
    controlActive,
    cart
}) => {
    const { isActive, reason } = controlActive
    const [promoError, setPromoError] = useState(false)
    const theme = useTheme()
    const dispatch = useDispatch()

    const { data, loading } = useSelector(selectLoyaltyData)

    const [textForLabel, setTextForLabel] = useState<string>('Промокод')
    const [isShowButton, setIsShowButton] = useState<boolean>(false)
    const captions = {
        apply: 'Применить',
        cancel: 'Отменить',
    }
    const [buttonCaption, setButtonCaption] = useState(captions.apply)
    const [isActiveTextField, setIsActiveTextField] = useState<boolean>(true)

    const formik = useFormik({
        initialValues: { promocode: '' },
        enableReinitialize: true,
        onSubmit: () => {
            //
        },
    })

    const handleTextFieldChange = (
      e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        formik.setFieldValue('promocode', e.target.value)
        formik.setErrors({})
        setTextForLabel('Промокод')

        if (e.target.value !== '') {
            setIsShowButton(true)
            setButtonCaption(captions.apply)
        }
    }

    const handlePromocode = () => {
        if (buttonCaption === captions.cancel) {
            dispatch(checkoutActions.deletePromocode())
            setIsShowButton(false)
            formik.setErrors({})
            formik.setFieldValue('promocode', '')
            setTextForLabel('Промокод')
            setIsActiveTextField(true)
        }

        if (buttonCaption === captions.apply) {
            dispatch(checkoutActions.applyPromocode(formik.values.promocode))
        }
    }

    useEffect(() => {
        if (data.bonuses === 0) {
            formik.setErrors({})
            formik.setFieldValue('promocode', '')
            setTextForLabel('Промокод')
            setIsActiveTextField(true)
            return
        }
    }, [data.bonuses])

    useEffect(() => {

        if (data.bonuses > 0) {
            formik.setErrors({})
            formik.setFieldValue('promocode', '')
            setTextForLabel('Нельзя использовать б.р. и промокод вместе')
            setIsActiveTextField(false)
        }

        if (data.promocode_error !== '' && formik.values.promocode !== '') {
            formik.setErrors({ promocode: data.promocode_error.slice(0, -3) })
            setTextForLabel(data.promocode_error.slice(0, -3))
            setIsShowButton(false)
            return
        }

        if (Number(cart.attributes.mindbox_data?.coupon_discount) > 0) {
            formik.setErrors({})
            formik.setFieldValue('promocode', '')
            setTextForLabel('Успешно применен')
            setIsShowButton(true)
            setPromoError(false)
            setButtonCaption(captions.cancel)
            setIsActiveTextField(false)
            return
        }

        if (cart.attributes.mindbox_data?.coupon_discount === 0) {
            dispatch(checkoutActions.deletePromocode())
            formik.setErrors({promocode: "promocode_undefined"})
            formik.setFieldValue('promocode', '')
            setTextForLabel('Промокод недействителен')
            setPromoError(true)
            setIsShowButton(false)
            setIsActiveTextField(true)
            return
        }
    }, [data, cart, isActive])


    useEffect(() => {
        if (Number(cart.attributes.mindbox_data?.coupon_discount) === 0 && data.promocode_error !== '' && formik.values.promocode !== '') {
            formik.setErrors({})
            formik.setFieldValue('promocode', '')
            setTextForLabel('Промокод')
            return
        }
    }, [])

    return (
      <>
          <Typography
            variant="body2"
            color={
                isActive
                  ? theme.palette.text.primary
                  : theme.palette.grey[400]
            }
            fontWeight={500}
          >
              Применить промокод
          </Typography>

          <TextField
            disabled={!isActiveTextField || !isActive}
            fullWidth
            variant="standard"
            label={isActive ? textForLabel : ' '}
            name="promocode"
            value={isActive ? formik.values.promocode : reason}
            error={!!formik.errors.promocode || promoError}
            onChange={handleTextFieldChange}
            sx={{
                '& .Mui-error': {
                    color: theme.palette.error.main,
                },
            }}
            InputProps={{
                endAdornment: (
                  <>
                      {isActive && isShowButton && (
                        <InputAdornment position="start">
                            <LoadingButton
                              loading={loading}
                              onClick={handlePromocode}
                              sx={{
                                  textTransform: 'none',
                                  color:
                                    buttonCaption === captions.apply
                                      ? theme.palette.primary.main
                                      : theme.palette.text
                                        .disabled,
                                  fontWeight: 400,
                              }}
                            >
                                {buttonCaption}
                            </LoadingButton>
                        </InputAdornment>
                      )}
                  </>
                ),
            }}
          />
      </>
    )
}

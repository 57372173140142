import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

export interface LoyaltyItem {
    title: string
    sale: string
    details: string[]
}

interface BoxLoyaltyProps {
    item: LoyaltyItem
    labelItem?: LoyaltyItem
    left?: boolean
}

export const BoxLoyalty: React.FC<BoxLoyaltyProps> = ({
    item,
    labelItem,
    left,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))

    const oneLabelItem = (text: string) => (
        <Typography
            variant={'body2'}
            color={'grey.600'}
            fontWeight={400}
            textAlign={'center'}
            mt={3}
        >
            {text}
        </Typography>
    )

    const oneItem = (text: string, bold: boolean) => (
        <Box
            sx={{
                height: {
                    lg: '140px',
                },
                mt: {
                    xs: 0.5,
                    md: 0,
                },
                minWidth: {
                    md: '290px',
                    lg: '110px',
                },
            }}
        >
            <Typography
                variant={bold && !left ? 'h6' : 'body2'}
                fontWeight={left ? 400 : bold ? 500 : 400}
                textAlign={left ? 'left' : 'center'}
                color={
                    left
                        ? theme.palette.text.disabled
                        : theme.palette.text.primary
                }
            >
                {text}
            </Typography>
        </Box>
    )

    return (
        <>
            {(!isMobile || !left) && (
                <Box
                    p={4}
                    pl={!left ? 4 : 0}
                    sx={{
                        width: '100%',
                        border: {
                            xs: '1px solid #DEDEDE',
                            md: '2px solid transparent',
                        },
                        borderRadius: {
                            xs: 0,
                            md: '20px',
                        },
                        '&:hover': {
                            border: {
                                md: !left ? '2px solid #54A67F' : '',
                            },
                        },
                    }}
                >
                    <Box my={left ? '125px' : 0}>
                        <Typography
                            variant="h6"
                            fontWeight={700}
                            textAlign={'center'}
                            mb={isMobile ? 3 : 11}
                        >
                            {item.title}
                        </Typography>
                    </Box>
                    {isMobile && oneLabelItem(labelItem?.sale || '')}
                    {oneItem(item.sale, true)}
                    {item.details.map((row, index) => {
                        return (
                            <React.Fragment key={index}>
                                {isMobile &&
                                    oneLabelItem(
                                        labelItem?.details[index] || ''
                                    )}

                                {oneItem(row, false)}
                            </React.Fragment>
                        )
                    })}
                </Box>
            )}
        </>
    )
}

import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { BrandedPackaging } from './BrandedPackaging'
import { DariaSoul } from './DariaSoul'
import { Delivery } from './Delivery'
import { DolyamePage } from './DolyamePage'
import { Franchise } from './Franchise'
import { HistoryPage } from './HistoryPage'
import { IsisPlatform } from './IsisPlatform'
import { LoyaltyProgram } from './LoyaltyProgram'
import { ReturnExchange } from './ReturnExchange'
import { RingSize } from './RingSize'
import { ServiceDepartmentClients } from './ServiceDepartmentClients'
import { SustainableDevelopment } from './SustainableDevelopment'

export const StaticPages: React.FC = () => {
    return (
        <>
            <Switch>
                <Route exact path="/static/delivery" component={Delivery} />
                <Route exact path="/static/daria_soul" component={DariaSoul} />
                <Route exact path="/static/history" component={HistoryPage} />
                <Route
                    exact
                    path="/static/isis_platform"
                    component={IsisPlatform}
                />
                <Route
                    exact
                    path="/static/loyalty_program"
                    component={LoyaltyProgram}
                />
                <Route exact path="/static/ring_size" component={RingSize} />
                <Route
                    exact
                    path="/static/service_department_clients"
                    component={ServiceDepartmentClients}
                />
                <Route
                    exact
                    path="/static/sustainable_development"
                    component={SustainableDevelopment}
                />
                <Route
                    exact
                    path="/static/branded_packaging"
                    component={BrandedPackaging}
                />
                <Route
                    exact
                    path="/static/return_exchange"
                    component={ReturnExchange}
                />
                <Route exact path="/static/franchise" component={Franchise} />
                <Route exact path="/static/dolyame" component={DolyamePage} />
            </Switch>
        </>
    )
}

import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { cartActions } from 'app/modules/Cart/slice'
import { EStatus } from 'types'
import { ILineItem, ILineItemUpdateProps } from 'types/ILineItem'
import { ResourceObject, Response } from 'utils/json-api'
import { isILineItem } from 'utils/json-api/resourceCheckers'

import { ListItemsState } from './types'

export const listItemsAdapter = createEntityAdapter<ILineItem>()

const slice = createSlice({
    name: 'listItems',
    initialState: listItemsAdapter.getInitialState<ListItemsState>({
        ids: [],
        entities: {},
        status: EStatus.INITIAL,
    }),
    reducers: {
        listItemsLoaded(
            state,
            action: PayloadAction<
                Response<
                    ResourceObject | ResourceObject[],
                    (ILineItem | ResourceObject)[]
                >
            >
        ) {
            const listItems = action.payload.included.filter(isILineItem)

            listItemsAdapter.setMany(state, listItems)
        },
        createListItem(_state, _action: PayloadAction<ILineItem>) {
            //
        },
        listItemCreated(state, action: PayloadAction<ILineItem>) {
            listItemsAdapter.setOne(state, action)
        },
        updateListItem(_state, _action: PayloadAction<ILineItem>) {
            //
        },
        listItemUpdated(state, action: PayloadAction<ILineItem>) {
            listItemsAdapter.setOne(state, action)
        },
        statusError(state) {
            state.status = EStatus.ERROR
        },
    },
    extraReducers: {
        [cartActions.updateItem.type]: (
            state,
            action: PayloadAction<ILineItemUpdateProps>
        ) => {
            const { selectById } = listItemsAdapter.getSelectors()
            const listItem = selectById(state, action.payload.line_item_id)
            if (listItem) {
                listItemsAdapter.setOne(state, {
                    ...listItem,
                    attributes: {
                        ...listItem.attributes,
                        quantity: action.payload.quantity,
                    },
                })
            }
        },
        [cartActions.deleteItem.type]: (
            state,
            action: PayloadAction<string>
        ) => {
            listItemsAdapter.removeOne(state, action.payload)
        },
    },
})

export const { actions: listItemsActions, reducer: listItemsReducer } = slice

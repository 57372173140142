import { Box, useMediaQuery, useTheme } from '@mui/material'
import {
    AddProductLists,
    mindboxAddToList,
    mindboxViewProduct,
} from 'app/features/MindBox'
import { selectAccountWholesale } from 'app/modules/Account/slice/selectors'
import { productsActions } from 'app/modules/Products/slice'
import {
    selectProductBySlug,
    selectProductStatus,
} from 'app/modules/Products/slice/selectors'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EStatus } from 'types'
import { IProduct } from 'types/IProduct'
import { useYandexMetricaEcommerce } from 'utils/yandexMetrica'

import { useProductVisualContent } from '../../hooks/useProductVisualContent'
import { IProductVisualContent } from '../../slice/types'
import { NoveltyProductListSliderContainer } from '../ProductListSliderContainer'
import { DesktopProduct } from './DesktopProduct'
import { MobileProduct } from './MobileProduct'

interface ProductWrapperProps {
    slug: string
}

export interface ProductProps {
    product: IProduct
    productVisualContent: IProductVisualContent[]
}

export const ProductWrapper: React.FC<ProductWrapperProps> = ({ slug }) => {
    const dispatch = useDispatch()
    const getProduct = useSelector(selectProductBySlug)
    const product = getProduct(slug)
    const status = useSelector(selectProductStatus)
    const wholesale = useSelector(selectAccountWholesale)

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    const { yandexMetricaEcommerceDetail } = useYandexMetricaEcommerce()

    useEffect(() => {
        window.scrollTo(0, 0)

        dispatch(productsActions.loadProduct(slug))
    }, [])

    useEffect(() => {
        if (!!product) {
            yandexMetricaEcommerceDetail(product)
            mindboxViewProduct({ product }, wholesale)
            mindboxAddToList(product, AddProductLists.addToWathed, wholesale)
        }
    }, [product?.id])


    const { productVisualContent } = useProductVisualContent(product)

    if (status === EStatus.INITIAL || status === EStatus.PENDING) {
        return null
    }

    if (!product || status === EStatus.ERROR) {
        return (
            <Box sx={{ mt: { xs: '40px' } }}>
                <NoveltyProductListSliderContainer
                    showTitle={true}
                    title={'К сожалению мы не смогли найти такую страницу'}
                    subTitle={'Вам может понравиться'}
                />
            </Box>
        )
    }

    const childProps: ProductProps = { product, productVisualContent }
    return (
        <>
            {isMobile && <MobileProduct {...childProps} />}

            {isDesktop && <DesktopProduct {...childProps} />}
        </>
    )
}

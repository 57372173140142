import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import { Badge, Box, Button, useTheme } from '@mui/material'
import { CartIcon } from 'app/components/Icons/CartIcon'
import { PartnerIcon } from 'app/components/Icons/PartnerIcon'
import { UserIcon } from 'app/components/Icons/UserIcon'
import {
    selectAccount,
    selectAccountWholesale,
} from 'app/modules/Account/slice/selectors'
import { authActions } from 'app/modules/Auth/slice'
import { selectAuthStatus } from 'app/modules/Auth/slice/selectors'
import { cartActions } from 'app/modules/Cart/slice'
import { selectCart } from 'app/modules/Cart/slice/selectors'
import { HeaderColorVariant } from 'app/modules/Layout/types/HeaderColorVariant'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { whichPageIsOpen } from 'utils/isSearchPageOpen'

import { HeaderActionIcon } from './HeaderActionIcon'
import { HeaderButton } from './HeaderProfileButton'

interface HeaderPrimaryActionsProps {
    variant: HeaderColorVariant
}

export const HeaderPrimaryActions: React.FC<HeaderPrimaryActionsProps> =
    React.memo(({ variant }) => {
        const dispatch = useDispatch()
        const theme = useTheme()
        const history = useHistory()
        const cart = useSelector(selectCart)
        const { notAuthorized, isAuthorized } = useSelector(selectAuthStatus)
        const account = useSelector(selectAccount)
        const wholesale = useSelector(selectAccountWholesale)

        const userName =
            account?.attributes.firstname ||
            account?.attributes.phone ||
            account?.attributes.email ||
            ''

        const colorByVariant = (() => {
            switch (variant) {
                case 'dark':
                    return theme.palette.getContrastText('#FFFFFF')
                case 'light':
                    return theme.palette.getContrastText('#000000')
                default:
                    return ''
            }
        })()

        const favoriteIconColor = whichPageIsOpen().includes('favorites')
            ? theme.palette.primary.main
            : colorByVariant

        const profileIconColor = whichPageIsOpen().includes('profile')
            ? theme.palette.primary.main
            : colorByVariant

        const handleOpenFavorites = useCallback(() => {
            if (notAuthorized) {
                dispatch(authActions.setReferrerUrl('/profile/personal'))
                dispatch(authActions.resetPhone())
                dispatch(authActions.setModalStatus('phone'))
            }
            if (isAuthorized) {
                history.push('/profile/favorites')
            }
        }, [isAuthorized, notAuthorized])

        const handleOpenCart = useCallback(() => {
            dispatch(cartActions.showCart())
        }, [])

        const handleOpenProfile = useCallback(() => {
            if (notAuthorized) {
                dispatch(authActions.setReferrerUrl('/profile/personal'))
                dispatch(authActions.resetPhone())
                dispatch(authActions.setModalStatus('phone'))
            }
            if (isAuthorized) {
                history.push('/profile/personal')
            }
        }, [isAuthorized, notAuthorized])

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '26px',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-start',
                }}
            >
                <HeaderButton
                    handleClick={handleOpenProfile}
                    iconColor={profileIconColor}
                >
                    <HeaderActionIcon text={userName || 'Вход'}>
                        {wholesale ? (
                            <PartnerIcon color={profileIconColor} />
                        ) : (
                            <UserIcon color={profileIconColor} />
                        )}
                    </HeaderActionIcon>
                </HeaderButton>

                <HeaderButton
                    handleClick={handleOpenFavorites}
                    iconColor={favoriteIconColor}
                >
                    <HeaderActionIcon text="Избранное">
                        <FavoriteBorderIcon />
                    </HeaderActionIcon>
                </HeaderButton>

                {!wholesale && (
                    <HeaderButton
                        handleClick={handleOpenCart}
                        iconColor={colorByVariant}
                    >
                        <HeaderActionIcon text="Корзина">
                            <Badge
                                color="primary"
                                badgeContent={
                                    cart?.relationships.line_items.data.length
                                }
                            >
                                <CartIcon color={colorByVariant} />
                            </Badge>
                        </HeaderActionIcon>
                    </HeaderButton>
                )}

                {wholesale && (
                    <Button
                        variant="text"
                        startIcon={<CartIcon color={colorByVariant} />}
                        onClick={handleOpenCart}
                        sx={{
                            textTransform: 'uppercase',
                            color: colorByVariant,
                        }}
                    >
                        {cart?.attributes.display_total}
                    </Button>
                )}
            </Box>
        )
    })

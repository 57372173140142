import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { Modal } from 'app/components/Modal'
import React, { useState } from 'react'
import { IVariant } from 'types/IVariant'

import { SizeItemVariant } from './SizeItemVariant'

const SIZE_TABLE_ROWS = [
    ['46–47', '15'],
    ['48', '15,5'],
    ['49–51', '16'],
    ['52-54', '17'],
    ['55–57', '18'],
    ['58', '18,5'],
    ['59–60', '19'],
    ['61', '19,5'],
    ['62–63', '20'],
    ['64', '20,5'],
    ['65–66', '21'],
    ['67', '21,5'],
    ['68-69', '22'],
] as const

export function getVarianSize(variant: IVariant) {
    return (variant.attributes.options_text.split('Размер: ').pop() || '0')
        .split('-')
        .map((val) =>
            isNaN(Number.parseFloat(val.replace(',', '.')))
                ? val
                : Number.parseFloat(val.replace(',', '.'))
        )
        .join('-')
}

export interface VariantsListProps {
    variants: IVariant[]
    selectedVariant: string | null
    setSelectedVariant: (newValue: string) => void
}

export const VariantsList: React.FC<VariantsListProps> = ({
    variants,
    selectedVariant,
    setSelectedVariant,
}) => {
    const [tooltipIsOpen, setTooltipOpen] = useState<boolean>(false)

    const handleCloseTooltip = () => {
        setTooltipOpen(false)
    }
    const handleOpenTooltip = () => {
        setTooltipOpen(true)
    }

    if (variants.length === 0) return null
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Typography
                    variant="h6"
                    sx={{ fontSize: '18px', fontWeight: 'medium' }}
                >
                    Размер
                </Typography>

                <Button onClick={handleOpenTooltip} sx={{ px: 0 }}>
                    <Typography
                        variant="body2"
                        sx={{ textTransform: 'none', fontWeight: 'medium' }}
                    >
                        Как определить размер?
                    </Typography>
                </Button>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: { xs: '2.5vw', sm: '10px' },
                    mt: { xs: '14px' },
                    mr: { xs: '-2.5vw', sm: '-10px' },
                    flexWrap: 'wrap',
                }}
            >
                {variants
                    .sort(
                        (a, b) =>
                            Number.parseFloat(
                                getVarianSize(a).split('-').shift() || '0'
                            ) -
                            Number.parseFloat(
                                getVarianSize(b).split('-').shift() || '0'
                            )
                    )
                    .map((variant, index) => (
                        <React.Fragment key={index}>
                            <SizeItemVariant
                                {...{
                                    variant,
                                    selectedVariant,
                                    setSelectedVariant,
                                }}
                            />
                        </React.Fragment>
                    ))}
            </Box>
            <Modal
                open={tooltipIsOpen}
                title={'подбор размера'}
                handleClose={handleCloseTooltip}
            >
                <Box
                    sx={{
                        pt: '24px',
                        pb: '12px',
                        px: 2,
                        width: {
                            md: '550px',
                        },
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        mt={1.5}
                        variant="body2"
                        fontWeight="regular"
                        width="100%"
                        textAlign={'left'}
                    >
                        {`Обмотайте нужный палец ниткой или лентой и замерьте полученную длину окружности.`}
                    </Typography>
                    <Typography
                        variant="body2"
                        fontWeight="regular"
                        width="100%"
                        textAlign="left"
                    >
                        {'По таблице определите размер кольца:'}
                    </Typography>
                </Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ px: 2, width: { xs: '170px' } }}>
                                Длина окружности (мм)
                            </TableCell>
                            <TableCell
                                sx={{
                                    px: 2,
                                    textAlign: 'right',
                                    verticalAlign: 'bottom',
                                }}
                            >
                                Размер кольца
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {SIZE_TABLE_ROWS.map((item, index) => (
                            <TableRow
                                key={index}
                                sx={(theme) => ({
                                    height: '40px',
                                    '&:nth-of-type(odd)': {
                                        backgroundColor:
                                            theme.palette.grey[100],
                                    },
                                })}
                            >
                                <TableCell sx={{ px: 2, py: 0 }}>
                                    {item[0]}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        px: 2,
                                        py: 0,
                                        textAlign: 'right',
                                    }}
                                >
                                    {item[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <Box sx={{ mt: '30px', mx: 2, pb: '16px' }}>
                    <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        color="primary"
                        sx={{
                            px: 1.75,
                            width: '100%',
                        }}
                        onClick={handleCloseTooltip}
                    >
                        <Typography variant="body2" fontWeight={500}>
                            ПОНЯТНО
                        </Typography>
                    </Button>
                </Box>
            </Modal>
        </>
    )
}

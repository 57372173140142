import {
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import { Box, Collapse, Typography } from '@mui/material'
import React, { useState } from 'react'

const DolyameFaqItem: React.FC<{
    title: string
    lastItem?: true
}> = ({ title, children, lastItem }) => {
    const [open, setOpen] = useState<boolean>(false)

    const handleClick = () => {
        setOpen(!open)
    }
    return (
        <Box
            py={'32px'}
            sx={{
                borderBottom: lastItem ? 'none' : '1px solid #DEDEDE',
                px: { xs: '24px', md: '40px' },
                cursor: 'pointer',
            }}
            onClick={handleClick}
        >
            <Box display={'flex'} justifyContent={'space-between'}>
                <Typography
                    variant="subtitle2"
                    sx={{
                        textTransform: 'uppercase',
                        fontSize: { xs: '14px', md: '18px' },
                        fontWeight: 'medium',
                    }}
                >
                    {title}
                </Typography>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Box>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Typography mt={3}>{children}</Typography>
            </Collapse>
        </Box>
    )
}

export const DolyameFAQ: React.FC = () => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
                variant="h1"
                fontWeight="medium"
                sx={{ fontSize: { xs: '24px', md: '42px' } }}
            >
                Частые вопросы
            </Typography>

            <Box
                sx={{
                    mt: { xs: '32px', md: '50px' },
                    borderRadius: '28px',
                    border: '1px solid #DEDEDE',
                    width: '100%',
                    maxWidth: { md: '63%' },
                }}
            >
                <DolyameFaqItem title={'Нужна ли регистрация в сервисе?'}>
                    Нет, отдельная регистрация не нужна. Мы зарегистрируем вас
                    автоматически после совершения покупки по номеру телефона.
                    Далее по этому номеру вы сможете войти в мобильное
                    приложение, чтобы отслеживать следующие платежи.
                </DolyameFaqItem>
                <DolyameFaqItem title={'Как купить товар?'}>
                    Выберите товар на нашем сайте, добавьте его в корзину и
                    переходите к оплате. На шаге выбора способа оплаты выберите
                    «Оплатить Долями» и совершите покупку как при оплате обычной
                    картой.
                </DolyameFaqItem>
                <DolyameFaqItem title={'Как работает сервис?'}>
                    Выберите Долями как способ оплаты при оформлении заказа и
                    введите данные карты. Мы разделим оплату на 4 части — первая
                    спишется сразу, следующие три — через каждые две недели.
                    Перед каждым платежом пришлем вам напоминание о дате
                    списания.
                </DolyameFaqItem>
                <DolyameFaqItem
                    title={'Есть ли плата за использование сервиса?'}
                >
                    Нет, мы не берем процентов или комиссий. Вы заплатите ровно
                    сумму покупки, разделенную на 4 части.
                </DolyameFaqItem>
                <DolyameFaqItem
                    title={'Это кредит? Проверяете ли вы кредитную историю?'}
                >
                    Нет, это не кредит. Мы не проверяем кредитную историю и не
                    заключаем кредитный договор с покупателем. В Долями нет
                    процентной ставки — вы платите только сумму покупки
                    разделенную на 4 части.
                </DolyameFaqItem>
                <DolyameFaqItem
                    title={'Куда переводить деньги в день платежа?'}
                    lastItem
                >
                    В дату оплаты сервис Долями будет автоматически списывать
                    средства с вашей карты. Вам необходимо только
                    удостовериться, что на карте есть нужная сумма. Вы можете
                    оплатить ближайший платеж раньше или погасить всю оставшуюся
                    сумму через мобильное приложение.
                </DolyameFaqItem>
            </Box>
        </Box>
    )
}

import { Stack, Typography, useTheme } from '@mui/material'
import { HeaderColorVariant } from 'app/modules/Layout/types/HeaderColorVariant'
import React from 'react'
import { Link } from 'react-router-dom'
import { whichPageIsOpen } from 'utils/isSearchPageOpen'

import { HeaderLocationSwitch } from '../HeaderLocationSwitch'
import { HeaderPhone } from '../HeaderPhone'

interface HeaderPrimaryLinksProps {
    variant: HeaderColorVariant
}

export const HeaderPrimaryLinks = React.memo<HeaderPrimaryLinksProps>(
    ({ variant }) => {
        const theme = useTheme()

        const colorByVariant = (() => {
            switch (variant) {
                case 'dark':
                    return theme.palette.text.primary
                case 'light':
                    return theme.palette.common.white
                default:
                    return ''
            }
        })()

        return (
            <Stack
                sx={{
                    alignItems: 'center',
                    position: 'relative',
                    zIndex: 1,
                }}
                spacing={'30px'}
                direction="row"
            >
                <HeaderLocationSwitch variant={variant} />

                <HeaderPhone variant={variant} />

                <Typography
                    color={
                        whichPageIsOpen().includes('store')
                            ? theme.palette.primary.main
                            : colorByVariant
                    }
                    variant="body3"
                    fontWeight={500}
                    component={Link}
                    to={'/store'}
                >
                    Магазины
                </Typography>

                <Typography
                    color={
                        whichPageIsOpen().includes('journal')
                            ? theme.palette.primary.main
                            : colorByVariant
                    }
                    variant="body3"
                    fontWeight={500}
                    component={Link}
                    to={'/journal'}
                >
                    Журнал
                </Typography>
            </Stack>
        )
    }
)

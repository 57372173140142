import { Box, Container, Divider, Typography } from '@mui/material'
import { ContactBlock } from 'app/components/ContactBlock'
import { MobileMenuItem } from 'app/components/MobileMenuItem'
import { ReactComponent as PlusIcon } from 'app/components/StaticPagesNavigation/assets/plus_icon.svg'
import { ExperienceISBlock } from 'app/components/StaticPagesNavigation/ExperienceISBlock'
import { UniverseISBlock } from 'app/components/StaticPagesNavigation/UniverseISBlock'
import React from 'react'
import { Link } from 'react-router-dom'

import { Logo } from '../../Logo'

export const MobileFooter: React.FC = () => (
    <Box component="footer" sx={{ pt: 4, pb: 7, bgcolor: 'grey.100' }}>
        <Container>
            <Logo align="left" size="large" variant="dark" />
        </Container>

        <Box sx={{ mt: 4.75 }}>
            <ContactBlock />
        </Box>

        <Box
            sx={{
                mt: 3,
            }}
        >
            <Divider variant="fullWidth" />

            <UniverseISBlock />

            <Divider variant="fullWidth" />

            <ExperienceISBlock />

            <Divider variant="fullWidth" />

            <Container
                sx={{
                    py: 0.625,
                }}
            >
                <Link to={'/static/franchise'}>
                    <MobileMenuItem
                        text={'Партнерам'}
                        endContent={<PlusIcon />}
                    />
                </Link>
            </Container>

            <Divider variant="fullWidth" />
        </Box>

        <Container sx={{ mt: 5 }}>
            <Typography variant="body2" sx={{ py: 1.25 }}>
                <a
                    rel="noreferrer"
                    href="/personal-data-policy.pdf"
                    target="_blank"
                >
                    Политика обработки персональных данных
                </a>
            </Typography>

            <Typography variant="body2" sx={{ py: 1.25 }}>
                © 2016—2022 IslandSoul Russia | ИП Гришаева Марина Сергеевна
                (771617529223)
                {/* <br />
                    ТРЦ «Фестиваль», г. Москва, Мичуринский пр-кт,
                    Олимпийская деревня, д.3 к1 */}
            </Typography>
        </Container>
    </Box>
)

import { LoadingButton } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import useOnScreen from 'hooks/useOnScreen'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EStatus } from 'types'

import { productsActions } from '../../slice'
import { selectProductsStatus } from '../../slice/selectors'
import { useCategoryPageContext } from '../ProductsFilter/hooks/CategoryPageContext'

export const ProductCardListShowMoreButton: React.FC = () => {
    const dispatch = useDispatch()

    const ref = useRef()
    const isVisible = useOnScreen(ref)

    const productsStatusIsFinished =
        useSelector(selectProductsStatus) === EStatus.FINISHED

    const {
        products,
        totalPages,
        curentPage,
        totalProductsCount: total,
        productsRequest,
    } = useCategoryPageContext()

    const lastPage = curentPage >= totalPages

    const productsIsEmpty = !products.length

    const count = products.length

    const handleClickShowMore = () => {
        if (lastPage) {
            return
        }

        dispatch(
            productsActions.loadNextCategoryPageProducts({
                ...productsRequest,
                page: productsRequest.page + 1,
            })
        )
    }

    useEffect(() => {
        if (isVisible && productsStatusIsFinished) {
            handleClickShowMore()
        }
    }, [isVisible])

    const displayButton =
        !productsIsEmpty && !lastPage && !productsStatusIsFinished

    return (
        <Box ref={ref}>
            {displayButton && (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    mt={10}
                >
                    <Typography variant="body2">{`Показано ${count} из ${total}`}</Typography>

                    <LoadingButton
                        sx={{ mt: 2 }}
                        variant="banner"
                        color="dark"
                        loading={!productsStatusIsFinished}
                        onClick={handleClickShowMore}
                    >
                        Показать ещё
                    </LoadingButton>
                </Box>
            )}
        </Box>
    )
}

import 'react-toastify/dist/ReactToastify.min.css'

import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import ym, { YMInitializer } from 'react-yandex-metrika'

import { yandexMetricaSuccessfullPayment } from './../utils/yandexMetrica'
// import { SnowEffect } from './components/SnowEffect'
import { MAIN_PAGE_TITLE, PROJECT_NAME } from './constants'
import { YandexTranslateContextProvider } from './features/YandexTranslate'
import { RouterContextProvider } from './modules/Navigation/RouterContext'
import { Pages } from './pages'

export const App: React.FC = () => {
    useEffect(() => {
        if (yandexMetricaSuccessfullPayment()) {
            ym('reachGoal', 'successful payment')
        }
    }, [window.location.href])

    return (
        <>
            <BrowserRouter>
                <Helmet
                    titleTemplate={`%s - ${PROJECT_NAME}`}
                    defaultTitle={MAIN_PAGE_TITLE}
                />

                <YMInitializer
                    accounts={[88167866]}
                    options={{
                        clickmap: true,
                        trackLinks: true,
                        accurateTrackBounce: true,
                        webvisor: true,
                        ecommerce: 'dataLayer',
                    }}
                />

                {/* <SnowEffect /> */}

                <RouterContextProvider>
                    <YandexTranslateContextProvider>
                        <Pages />
                    </YandexTranslateContextProvider>
                </RouterContextProvider>
            </BrowserRouter>

            <ToastContainer />
        </>
    )
}

import { createSelector } from '@reduxjs/toolkit'
import { variantsAdapter } from 'app/modules/Variants/slice'
import { Nullable, RootState } from 'types'
import { IProduct } from 'types/IProduct'
import { IVariant } from 'types/IVariant'

import { productsAdapter } from '.'

const productsSelectors = productsAdapter.getSelectors()
const variantsSelectors = variantsAdapter.getSelectors()

const selectProductsDomain = (state: RootState) => state.products
const selectVariantsDomain = (state: RootState) => state.variants

export const selectProductsStatus = createSelector(
    [selectProductsDomain],
    (state) => state.loadProductsStatus
)
export const selectProductStatus = createSelector(
    [selectProductsDomain],
    (state) => state.loadProductStatus
)

export const selectSaleProducts = createSelector(
    [selectProductsDomain],
    (state) =>
        state.saleProductsIDs
            .map((saleProductID) =>
                productsSelectors.selectById(state, saleProductID)
            )
            .filter(Boolean) as IProduct[]
)

export const selectGiftProducts = createSelector(
    [selectProductsDomain],
    (state) =>
        state.giftProductsIDs
            .map((giftProductID) =>
                productsSelectors.selectById(state, giftProductID)
            )
            .filter(Boolean) as IProduct[]
)

export const selectFiltersParamsPrices = createSelector(
    [selectProductsDomain],
    (state) => ({
        max_price: state.filtersParams.max_price,
        min_price: state.filtersParams.min_price,
    })
)

export const selectProductsAllOptionTypes = createSelector(
    [selectProductsDomain],
    (state) => state.filtersParams.option_types
)

export const selectProductsOptionTypes = createSelector(
    [selectProductsDomain],
    (state) =>
        state.filtersParams.option_types.filter(
            (option) => option.name.toLocaleLowerCase() !== 'размер'
        )
)

export const selectProductsOptionSize = createSelector(
    [selectProductsDomain],
    (state) =>
        state.filtersParams.option_types.find(
            (option) => option.name.toLocaleLowerCase() === 'размер'
        )
)

export const selectProductsProperties = createSelector(
    [selectProductsDomain],
    (state) => state.filtersParams.product_properties
)

export const selectProductBySlug = createSelector(
    [selectProductsDomain],
    (state) => (slug: string) =>
        productsSelectors
            .selectAll(state)
            .find((value) => value.attributes.slug === slug)
)

export const selectProductVariants = createSelector(
    [selectProductsDomain, selectVariantsDomain],
    (productsState, variantsState) => (id: string) => {
        const product = productsSelectors.selectById(productsState, id)

        if (!product) {
            return []
        }

        return product.relationships.variants.data.reduce(
            (acc: IVariant[], value) => {
                const variant = variantsSelectors.selectById(
                    variantsState,
                    value.id
                )

                if (variant) {
                    return [...acc, variant]
                }

                return acc
            },
            []
        )
    }
)

export const selectCategoryPageData = createSelector(
    [selectProductsDomain],
    (state) => ({
        filter: state.categoryPage.filter,
        sort: state.categoryPage.sort,

        products: state.categoryPage.productsIDs
            .map((categoryPageProductID) =>
                productsSelectors.selectById(state, categoryPageProductID)
            )
            .filter(Boolean) as IProduct[],
        curentPage: state.categoryPage.curentPage,
        totalPages: state.categoryPage.totalPages,
        totalProductsCount: state.categoryPage.totalProductsCount,

        loadProducts: state.categoryPage.loadProducts,
    })
)

export const selectSearchPageData = createSelector(
    [selectProductsDomain],
    (state) => ({
        products: state.searchPage.productsIDs
            .map((searchPageProductID) =>
                productsSelectors.selectById(state, searchPageProductID)
            )
            .filter(Boolean) as IProduct[],
        totalPages: state.searchPage.totalPages,
        totalProductsCount: state.searchPage.totalProductsCount,
    })
)

export const selectFavoritesProductsData = createSelector(
    [selectProductsDomain],
    (state) => ({
        products: state.favoritesPage.productsIDs
            .map((favoritesProductID) =>
                productsSelectors.selectById(state, favoritesProductID)
            )
            .filter(Boolean) as IProduct[],

        totalPages: state.favoritesPage.totalPages,
        totalProductsCount: state.favoritesPage.totalProductsCount,
    })
)

export const selectNoveltyProducts = createSelector(
    [selectProductsDomain],
    (state) =>
        state.noveltyProductsIDs
            .map((noveltyProductID) =>
                productsSelectors.selectById(state, noveltyProductID)
            )
            .filter(Boolean) as IProduct[]
)

export const selectProductById = createSelector(
    [selectProductsDomain],
    (state) => (id?: Nullable<string>) =>
        id ? productsSelectors.selectById(state, id) : undefined
)

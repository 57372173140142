import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { selectHeaderCategories } from 'app/modules/Categories/slice/selectors'
import { HeaderColorVariant } from 'app/modules/Layout/types/HeaderColorVariant'
import {useCategoryPageContext} from "app/modules/Products/components/ProductsFilter/hooks"
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

interface HeaderCategoriesProps {
    variant: HeaderColorVariant
}

export const HeaderCategories = React.memo<HeaderCategoriesProps>(
    ({ variant }) => {
        const theme = useTheme()
        const greaterThanWXGA = useMediaQuery('(min-width:1366px)')
        const categories = useSelector(selectHeaderCategories)

      const {handleClearFilter} =
        useCategoryPageContext()

      const colorByVariant = (() => {
            switch (variant) {
                case 'dark':
                    return theme.palette.getContrastText('#FFFFFF')
                case 'light':
                    return theme.palette.getContrastText('#000000')
                default:
                    return ''
            }
        })()

        return (
            <Box
                sx={{
                    py: { md: '25px' },
                    display: 'flex',
                    gap: greaterThanWXGA ? '30px' : '32px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexGrow: 2,
                }}
            >
                {categories.map((category) => (
                    <Typography
                        key={category.id}
                        sx={{
                            color: colorByVariant,
                            textTransform: 'uppercase',
                        }}
                        to={`/${category.attributes.permalink}`}
                        variant={greaterThanWXGA ? 'body2' : 'body3'}
                        fontWeight="medium"
                        component={Link}
                    >
                        {category.attributes.name}
                    </Typography>
                ))}
            </Box>
        )
    }
)

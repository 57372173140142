import { Box, Typography } from '@mui/material'
import { ReactComponent as AddIcon } from 'app/modules/Cart/assets/AddIcon.svg'
import { ReactComponent as RemoveIcon } from 'app/modules/Cart/assets/RemoveIcon.svg'
import { cartActions } from 'app/modules/Cart/slice'
import React from 'react'
import { useDispatch } from 'react-redux'
import { ILineItem } from 'types/ILineItem'

import { QuantityControlButton } from './QuantityControlButton'

interface QuantityControlProps {
    item: ILineItem
}

export const QuantityControl: React.FC<QuantityControlProps> = ({ item }) => {
    const dispatch = useDispatch()

    const handleAddCount = () => {
        dispatch(
            cartActions.updateItem({
                line_item_id: item.id,
                quantity: item.attributes.quantity + 1,
            })
        )
    }

    const handleSubCount = () => {
        if (item.attributes.quantity > 1) {
            dispatch(
                cartActions.updateItem({
                    line_item_id: item.id,
                    quantity: item.attributes.quantity - 1,
                })
            )
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <QuantityControlButton onClick={handleSubCount}>
                <RemoveIcon />
            </QuantityControlButton>

            <Typography sx={{ width: 24 }} textAlign="center">
                {item.attributes.quantity}
            </Typography>

            <QuantityControlButton onClick={handleAddCount}>
                <AddIcon />
            </QuantityControlButton>
        </Box>
    )
}

import { EStatus } from 'types'
import { ICustomerInformation } from 'types/ICustomerInformation'
import { IMindBoxData } from 'types/IMindBoxData'
import { IPartnerInformation } from 'types/IPartnerInformation'
import { IPaymentsShipmentsAttributes } from 'types/IPaymentsShipmentsAttributes'

export enum LoyaltyPromoType {
    bonuses = 'bonuses',
    promocode = 'promocode',
    none = 'none',
}

export interface CheckoutState {
    status: EStatus
    forms: {
        partnerInformation: {
            status: EStatus
            data: IPartnerInformation
        }
        customerInformation: {
            status: EStatus
            data: ICustomerInformation
        }
        paymentsShipmentsAttributes: {
            loading: boolean
            data: IPaymentsShipmentsAttributes
        }
        loyalty: {
            loading: boolean
            data: {
                available_bonuses: number
                bonuses: number
                promocode_total: number
                promocode_error: string
                whichPromoUsed: LoyaltyPromoType
            }
        }
        mindBoxData: IMindBoxData
    }
}

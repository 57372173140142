import { Box, Typography } from '@mui/material'
import { MobileToggleList } from 'app/components/MobileToggleList'
import { Modal } from 'app/components/Modal'
import { citiesActions } from 'app/modules/Cities/slice'
import { selectCurrentCity } from 'app/modules/Cities/slice/selectors'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useHeaderContext } from '../HeaderContextProvider'
import { HeaderLocationChildProps } from '.'

export const MobileHeaderLocation: React.FC<HeaderLocationChildProps> = ({
    countriesData,
}) => {
    const dispatch = useDispatch()

    const { isCityOpened, cityClose } = useHeaderContext()

    const currentCity = useSelector(selectCurrentCity)

    const handleChangeCurrentCity = (id: string) => {
        if (currentCity?.id !== id)
            dispatch(citiesActions.changeCurrentCity(id))
    }

    useEffect(() => {
        cityClose?.()
    }, [currentCity])

    return (
        <Modal
            open={!!isCityOpened}
            title={'выберите город'}
            handleClose={() => cityClose?.()}
        >
            <Box pb={10}>
                {countriesData
                    .filter((countryData) => countryData.cities.length > 0)
                    .map((countryData, index) => (
                        <MobileToggleList key={index} text={countryData.name}>
                            {countryData.cities.map((city) => (
                                <Box
                                    key={city.id}
                                    sx={(theme) => ({
                                        cursor: 'pointer',
                                        color:
                                            city.id === currentCity?.id
                                                ? theme.palette.primary.main
                                                : 'black',
                                    })}
                                    py={1.25}
                                    display={'flex'}
                                    alignItems={'center'}
                                    onClick={() =>
                                        handleChangeCurrentCity(city.id)
                                    }
                                >
                                    <Typography variant="body2">
                                        {city.attributes.name}
                                    </Typography>
                                </Box>
                            ))}
                        </MobileToggleList>
                    ))}
            </Box>
        </Modal>
    )
}

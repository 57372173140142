import {
    Box,
    Button,
    Container,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { Modal } from 'app/components/Modal'
import {
    selectAccount,
    selectAccountWholesale,
} from 'app/modules/Account/slice/selectors'
import { authActions } from 'app/modules/Auth/slice'
import { selectAuthStatus } from 'app/modules/Auth/slice/selectors'
import { CartItems } from 'app/modules/Cart/components/CartItems'
import { cartActions } from 'app/modules/Cart/slice'
import {
    selectCart,
    selectCartOpen,
    selectIsCartEmpty,
    selectIsCreated,
} from 'app/modules/Cart/slice/selectors'
import { checkoutActions } from 'app/modules/Checkout/slice'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import ym from 'react-yandex-metrika'
import { getCookieValue } from 'utils/getCookieValue'
import {
    googleAnalyticsEvent,
    googleAnalyticsEvents,
} from 'utils/googleAnalytics'

// import { ReactComponent as RibbonIcon } from '../assets/Ribbon.svg'

export const Cart: React.FC = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const { isAuthorized, notAuthorized } = useSelector(selectAuthStatus)
    const isOpen = useSelector(selectCartOpen)
    const cart = useSelector(selectCart)
    const isCartCreated = useSelector(selectIsCreated)
    const isCartEmpty = useSelector(selectIsCartEmpty)
    const account = useSelector(selectAccount)
    const wholesale = useSelector(selectAccountWholesale)

    const handleClose = () => {
        dispatch(cartActions.hideCart())
    }

    const loadCart = () => {
        if (isCartCreated) {
            dispatch(cartActions.loadCart())
        } else {
            dispatch(cartActions.createCart())
        }
    }

    useEffect(() => {
        if (notAuthorized) {
            loadCart()
        }
    }, [notAuthorized])

    useEffect(() => {
        if (!account) return
        if (notAuthorized) return

        if (wholesale) {
            if (!cart) {
                dispatch(cartActions.getLastCart())
            } else {
                dispatch(cartActions.deleteCartState())
                dispatch(cartActions.deleteCartToken())
                dispatch(cartActions.getLastCart())
            }
        } else {
            if (!cart) {
                loadCart()
            } else if (cart.relationships.user.data?.id !== account?.id) {
                dispatch(cartActions.cartAssociate())
            }
        }
    }, [account?.id, notAuthorized])

    const handleCreateOrder = () => {
        ym('reachGoal', 'ordering')
        googleAnalyticsEvent(googleAnalyticsEvents.ordering)

        dispatch(
            checkoutActions.setRoistatVisit(getCookieValue('roistat_visit'))
        )

        dispatch(cartActions.hideCart())

        if (isAuthorized) {
            history.push('/orders/create')
        }
        if (notAuthorized) {
            dispatch(authActions.setReferrerUrl('/orders/create'))
            dispatch(authActions.resetPhone())
            dispatch(authActions.setModalStatus('phone'))
        }
    }

    const handleEmptyCart = () => {
        dispatch(cartActions.emptyCart())
    }

    return (
        <Modal
            open={isOpen}
            title={
                <Box display="flex" justifyContent="space-between">
                    <Typography
                        sx={{
                            fontWeight: 500,
                            textTransform: 'uppercase',
                        }}
                        variant="h5"
                        ml={{ xs: '16px', md: '30px' }}
                    >
                        Корзина
                    </Typography>
                    <Button
                        onClick={handleEmptyCart}
                        sx={{
                            mr: { md: '84px' },
                            display: { xs: 'none', md: 'block' },
                            color: 'black',
                        }}
                    >
                        очистить
                    </Button>
                </Box>
            }
            handleClose={handleClose}
            height={'calc(100% - 32px)'}
        >
            <Container
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box sx={{ mt: 4 }}>
                    <CartItems />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        alignItems: 'flex-start',
                        mt: 4,
                        mb: isMobile ? 8 : 4,
                    }}
                >
                    {!isCartEmpty && (
                        <Box
                            sx={{
                                position: { xs: 'fixed', md: 'relative' },
                                bottom: { xs: '0px', md: 'unset' },
                                width: '100%',
                                py: { xs: '12px', md: 'unset' },
                                backgroundColor: {
                                    xs: 'white',
                                    md: 'transparent',
                                },
                            }}
                        >
                            <Button
                                fullWidth
                                variant="checkout"
                                onClick={handleCreateOrder}
                                sx={{
                                    width: {
                                        xs: 'calc(100% - 32px)',
                                        md: '100%',
                                    },
                                    borderRadius: '10px',
                                }}
                            >
                                <Typography
                                    sx={{ fontWeight: 500 }}
                                    variant="body1"
                                >
                                    {cart?.attributes.display_total}
                                </Typography>

                                <Typography
                                    sx={{ fontWeight: 500 }}
                                    variant="body2"
                                >
                                    Оформить заказ
                                </Typography>
                                {/* <Box
                                    sx={{
                                        position: 'absolute',
                                        right: '-16px',
                                        top: '-28px',
                                    }}
                                >
                                    <RibbonIcon />
                                </Box> */}
                            </Button>
                        </Box>
                    )}
                </Box>
            </Container>
        </Modal>
    )
}

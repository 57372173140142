import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import { selectCurrentCityPickup } from 'app/modules/Cities/slice/selectors'
import { ReactComponent as ArrowLeft16 } from 'app/modules/Products/assets/ArrowLeft16.svg'
import { ReactComponent as DeliveryDesktop } from 'app/modules/Products/assets/DeliveryDesktop.svg'
import { ReactComponent as DeliveryMobile } from 'app/modules/Products/assets/DeliveryMobile.svg'
import React from 'react'
import { useSelector } from 'react-redux'
import {IProduct} from "types/IProduct"

interface I {
  product: IProduct
}
export const ProductCityLink: React.FC<I> = ({product}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const currentCityPickup = useSelector(selectCurrentCityPickup)

    if (!product.attributes.delivery) return null

    return (
        <Box>
            <Button variant="contained" sx={{ pointerEvents: 'none' }}>
                {isMobile ? <DeliveryMobile /> : <DeliveryDesktop />}
                <Typography
                    variant="body1"
                    sx={{
                        textTransform: 'none',
                        ml: { xs: '10px' },
                        fontWeight: 'medium',
                        fontSize: { xs: '14px', md: '18px' },
                    }}
                >
                    Доставим из Москвы
                </Typography>
            </Button>
            <Typography
                variant="body1"
                fontSize="14px"
                mt="14px"
                lineHeight="24px"
            >
                Для уточнения наличия в розничном магазине вашего города
                напишите нам в онлайн-чат на сайте
            </Typography>
            {/* <Box component="a" href="/store" display="flex" alignItems="center">
                <Typography
                    variant="body1"
                    fontSize="14px"
                    fontWeight="medium"
                    lineHeight="24px"
                >
                    Перейти к списку магазинов
                </Typography>
                <ArrowLeft16 />
            </Box> */}
        </Box>
    )
}

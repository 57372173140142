import LanguageIcon from '@mui/icons-material/Language'
import { Box, Container, Divider, Grid, Stack, Typography } from '@mui/material'
import { LanguageSwitch } from 'app/features/YandexTranslate/LanguageSwitch'
import { selectHeaderCategories } from 'app/modules/Categories/slice/selectors'
import { selectCurrentCity } from 'app/modules/Cities/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { ReactComponent as TelegramIcon } from '../assets/telegram_icon.svg'
import { ReactComponent as VKIcon } from '../assets/vk_icon.svg'
import { ReactComponent as WhatsAppIcon } from '../assets/whatsapp_icon.svg'
import { ReactComponent as YoutubeIcon } from '../assets/youtube_icon.svg'

export const DesktopFooter: React.FC = () => {
    const categories = useSelector(selectHeaderCategories)
    const currentCity = useSelector(selectCurrentCity)

    return (
        <Box component="footer" bgcolor="grey.100">
            <Container>
                <Grid container py={7.5} spacing={3.75} wrap="wrap">
                    <Grid item xs={12} md={4} lg>
                        <Box
                            mb={4}
                            display={'flex'}
                            alignItems={'center'}
                            flexWrap={'wrap'}
                        >
                            <Typography
                                sx={{
                                    textTransform: 'uppercase',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                }}
                                mr={2}
                                mb={1}
                                variant="body2"
                                fontWeight="medium"
                            >
                                <LanguageIcon
                                    color="primary"
                                    sx={{ pr: 0.5, pb: 0.25 }}
                                />
                                <span>Island Soul</span>
                            </Typography>

                            <Typography
                                sx={{
                                    textTransform: 'uppercase',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                }}
                                mb={1}
                                variant="body2"
                                fontWeight="medium"
                            >
                                {currentCity?.attributes.name}
                            </Typography>
                        </Box>

                        <Stack spacing={1}>
                            <LanguageSwitch />

                            <Typography variant="body2">
                                <a href="tel:+78002012323">8 (800) 201-23-23</a>
                            </Typography>

                            <Typography variant="body2">
                                <Link to="/store">Магазины</Link>
                            </Typography>
                        </Stack>

                        <Stack
                            sx={{ mt: 2.5 }}
                            spacing={3}
                            direction="row"
                            alignItems="center"
                        >
                            {/*<Box
                              sx={{ display: 'flex' }}
                              rel="noreferrer"
                              href="https://www.instagram.com/island_soul_jewelry"
                              target="_blank"
                              component="a"
                            >
                                <InstagramIcon />
                            </Box>*/}

                            <Box
                                sx={{ display: 'flex' }}
                                rel="noreferrer"
                                href="https://m.vk.com/public211205674"
                                target="_blank"
                                component="a"
                            >
                                <VKIcon />
                            </Box>

                            <Box
                                sx={{ display: 'flex' }}
                                rel="noreferrer"
                                href="https://t.me/+6n-0K5F5BblhNjVi"
                                target="_blank"
                                component="a"
                            >
                                <TelegramIcon />
                            </Box>

                            <Box
                                sx={{ display: 'flex' }}
                                rel="noreferrer"
                                href="https://wa.me/79999718174"
                                target="_blank"
                                component="a"
                            >
                                <WhatsAppIcon />
                            </Box>

                            <Box
                                sx={{ display: 'flex' }}
                                rel="noreferrer"
                                href="https://www.youtube.com/channel/UCtHo8K1aLV2nd-Xdi3J4eTQ"
                                target="_blank"
                                component="a"
                            >
                                <YoutubeIcon />
                            </Box>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={4} lg>
                        <Typography
                            sx={{ textTransform: 'uppercase' }}
                            mb={4}
                            variant="body2"
                            fontWeight="medium"
                        >
                            Украшения
                        </Typography>

                        <Stack spacing={1}>
                            {categories.map((category, index) => (
                                <Typography key={index} variant="body2">
                                    <Link
                                        to={`/${category.attributes.permalink}`}
                                    >
                                        {category.attributes.name}
                                    </Link>
                                </Typography>
                            ))}
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={4} lg>
                        <Typography
                            sx={{ textTransform: 'uppercase' }}
                            mb={4}
                            variant="body2"
                            fontWeight="medium"
                        >
                            Вселенная Island Soul
                        </Typography>

                        <Stack spacing={1}>
                            <Link to={'/static/history'}>
                                <Typography variant="body2">
                                    История бренда
                                </Typography>
                            </Link>

                            {/* 
                                <Typography variant="body2">
                                    Подбор украшений
                                </Typography>
                            */}

                            <Link to={'/static/daria_soul'}>
                                <Typography variant="body2">
                                    Daria Soul
                                </Typography>
                            </Link>

                            <Link to={'/journal'}>
                                <Typography variant="body2">
                                    Журнал SoulLife
                                </Typography>
                            </Link>

                            {/* 
                                <Typography variant="body2">
                                    Занятия йогой
                                </Typography>

                                <Typography variant="body2">
                                    Пресс-центр
                                </Typography>

                                <Typography variant="body2">Карьера</Typography>
                             */}

                            <Link to={'/static/branded_packaging'}>
                                <Typography variant="body2">
                                    Фирменная упаковка
                                </Typography>
                            </Link>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md="auto" lg>
                        <Typography
                            sx={{ textTransform: 'uppercase' }}
                            mb={4}
                            variant="body2"
                            fontWeight="medium"
                        >
                            Island Soul Experience
                        </Typography>

                        <Stack spacing={1}>
                            <Link to={'/static/delivery'}>
                                <Typography variant="body2">
                                    Доставка и оплата
                                </Typography>
                            </Link>

                            <Link to={'/static/dolyame'}>
                                <Typography variant="body2">Долями</Typography>
                            </Link>

                            <Link to={'/static/service_department_clients'}>
                                <Typography variant="body2">
                                    Отдел обслуживания клиентов
                                </Typography>
                            </Link>

                            <Link to={'/static/ring_size'}>
                                <Typography variant="body2">
                                    Размеры колец
                                </Typography>
                            </Link>

                            <Link to={'/static/return_exchange'}>
                                <Typography variant="body2">
                                    Возврат и обмен
                                </Typography>
                            </Link>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md="auto" lg>
                        <Typography
                            sx={{ textTransform: 'uppercase' }}
                            mb={4}
                            variant="body2"
                            fontWeight="medium"
                        >
                            Партнерам
                        </Typography>

                        <Stack spacing={1}>
                            <Link to={'/static/franchise'}>
                                <Typography variant="body2">
                                    Франшиза
                                </Typography>
                            </Link>

                            {/* <Typography variant="body2">
                                Вход для партнеров
                            </Typography>

                            <Typography variant="body2">
                                Поставщикам товаров и услуг
                            </Typography> */}
                        </Stack>
                    </Grid>
                </Grid>
            </Container>

            <Divider variant="fullWidth" />

            <Container>
                <Box pt={2.375} pb={1.625}>
                    <Grid container spacing={9}>
                        <Grid item xs={12} lg="auto">
                            <Typography variant="body2">
                                © 2016—2022 IslandSoul Russia | ИП Гришаева
                                Марина Сергеевна (771617529223)
                                {/* <br />
                                ТРЦ «Фестиваль», г. Москва, Мичуринский пр-кт,
                                Олимпийская деревня, д.3 к1 */}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} lg>
                            <Typography variant="body2">
                                <a
                                    rel="noreferrer"
                                    href="/personal-data-policy.pdf"
                                    target="_blank"
                                >
                                    Политика обработки персональных данных
                                </a>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

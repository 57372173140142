import { Box, FormGroup } from '@mui/material'
import {selectHeaderCategories} from "app/modules/Categories/slice/selectors"
import { selectProductsProperties } from 'app/modules/Products/slice/selectors'
import {
    IProductProperty,
    IProductPropertyValue,
} from 'app/modules/Products/slice/types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {useLocation} from "react-router-dom"

import { useCategoryPageContext } from '../../hooks'
import { ProductsFilterKind } from '../../ProductsFilterKind'
import { ProductsFilterFormControlLabel } from './ProductsFilterFormControlLabel'
import { ProductsFilterShowMoreFiltersButton } from './ProductsFilterShowMoreFiltersButton'

const ProductsFilterProperty: React.FC<{ property: IProductProperty }> = ({
    property,
}) => {
    const [collapsed, setCollapsed] = useState(false)
    const headerCategories = useSelector(selectHeaderCategories)

    const router = useLocation()
    const category = headerCategories.find(i => i.attributes.permalink === router.pathname.slice(1))

    const { filterReadAdapter, handleChangeProductProperty } =
        useCategoryPageContext()

    useEffect(() => {
        setCollapsed(property.values.length > 5)
    }, [property])

    const checked = (property_value: IProductPropertyValue) =>
        Boolean(
            filterReadAdapter.properties?.[property.presentation]?.find(
                (filter_param) => filter_param === property_value.filter_param
            )
        )

    let propertyValues = collapsed
        ? property.values.slice(0, 5)
        : property.values

    if (property.name === 'Количество вставок') {
        const category = headerCategories.find(i => i.attributes.permalink === router.pathname.slice(1))
        propertyValues = propertyValues
            .map((i) => ({ ...i, value: +i.value }))
            .sort((a, b) => {
                return a.value - b.value
            })
            .map((i) => ({ ...i, value: i.value.toString() }))
    }

    if (category?.attributes.filters.properties.length && !category.attributes.filters.properties.find(i => i === property.presentation)) {
        return null
    }

    return (
        <ProductsFilterKind name={property.name}>
            <FormGroup>
                {propertyValues.map((property_value) => (
                    <Box
                        key={property_value.value}
                        sx={{
                            mb: { xs: '12px', lg: '2px' },
                            height: { xs: '24px', md: '28px' },
                        }}
                    >
                        <ProductsFilterFormControlLabel
                            labelText={property_value.value}
                            checked={checked(property_value)}
                            onChange={() => {
                                handleChangeProductProperty(
                                    property,
                                    property_value,
                                    !checked(property_value)
                                )
                            }}
                        />
                    </Box>
                ))}

                <ProductsFilterShowMoreFiltersButton
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                />
            </FormGroup>
        </ProductsFilterKind>
    )
}

export const ProductsFilterProperties: React.FC = () => {
    const productsProperties = useSelector(selectProductsProperties)

    return (
        <>
            {productsProperties.map((property, index) => {
                return (
                    <React.Fragment key={index}>
                        <ProductsFilterProperty property={property} />
                    </React.Fragment>
                )
            })}
        </>
    )
}

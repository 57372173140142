import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { INavigation } from './Navigation.types'
import MobileNavigation from './partials/MobileNavigation'

const Navigation: React.FC<INavigation> = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    return <>{isMobile && <MobileNavigation />}</>
}

export default Navigation

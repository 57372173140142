import {
    Box,
    Container,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(() =>
    createStyles({
        image: {
            width: '100%',
            height: 'auto',
        },
    })
)

interface RowProps {
    text?: string
}

const HeaderRow: React.FC<RowProps> = ({ text }) => (
    <Grid item xs display={'flex'} alignItems={'center'}>
        <Typography variant="body2" fontWeight={500} textAlign={'center'}>
            {text}
        </Typography>
    </Grid>
)
const TableRow: React.FC<RowProps> = ({ text }) => (
    <Grid item xs py={1}>
        <Typography variant="body2" textAlign={'center'}>
            {text}
        </Typography>
    </Grid>
)

export const RingSize: React.FC = () => {
    const classes = useStyles()

    const myRef = useRef<null | HTMLDivElement>(null)
    const { hash } = useLocation()

    useEffect(() => {
        if (hash === '#ring_size_table') {
            myRef?.current?.scrollIntoView()
        }
    }, [hash, myRef])

    const sizes = [
        ['14,86', '46,5', '15', '4', 'H'],
        ['15,27', 'От 47,8', '15,5', '4,5', 'I'],
        ['15,70', 'До 49,0', '15,5', '5', 'J'],
        ['16,10', '50,3', '16', '5,5', 'K'],
        ['16,51', '51,5', '16,5', '6', 'L'],
        ['16,92', '52,8', '17', '6,5', 'M'],
        ['17,35', 'От 54,0', '17,5', '7', 'N'],
        ['17,75', 'До 55,3', '17,5', '7,5', 'O'],
        ['18,19', '56,6', '18', '8', 'P'],
        ['18,53', '57,8', '18,5', '8,5', 'Q'],
        ['18,89', '59,1', '19', '9', 'R'],
        ['19,41', '60,3', '19,5', '9,5', 'S'],
        ['19,84', '61,6', '20', '10', 'T'],
        ['20,20', 'От 62,8', '20,5', '10,5', 'U'],
        ['20,68', 'До 64,1', '20,5', '11', 'V'],
        ['21,08', '65,3', '21', '11,5', 'W'],
        ['21,49', 'От 66,6', '21,5', '12', 'X'],
        ['21,89', 'До 67,9', '21,5', '12,5', 'Y'],
        ['22,33', '69,1', '22', '13', 'Z'],
    ]

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const widthVariant = isMobile ? '80px' : '149px'
    const heightVariant = isMobile ? '52px' : '97px'

    return (
        <Container
            sx={{
                mt: { md: 30, lg: 20, xl: 20 },
            }}
        >
            <Grid container>
                <Grid
                    item
                    xs={12}
                    lg={6}
                    display={'flex'}
                    alignItems={'center'}
                >
                    <Box px={{ sx: 0, md: 12 }}>
                        <Box
                            mt={{ xs: 3.2, md: 0 }}
                            sx={{
                                height: heightVariant,
                                width: widthVariant,
                            }}
                            component="img"
                            src="/assets/images/bg-is-stamp-loop2.png"
                            alt={'bg-is-stamp-loop'}
                        />

                        <Typography
                            variant={isMobile ? 'h5' : 'h4'}
                            fontWeight={500}
                            mt={{ xs: 2.4, md: 7 }}
                            sx={{
                                textTransform: 'uppercase',
                            }}
                        >
                            размеры колец
                        </Typography>
                        <Typography variant="body2" mt={4}>
                            Перед приобретением кольца необходимо знать его
                            точный размер. Это маленькое украшение способно
                            доставить массу хлопот, если окажется мало. А когда
                            изделие немножко велико, оно может незаметно
                            ускользнуть от своего владельца. Поэтому кольцо-
                            такой аксессуар, с размером которого нельзя
                            экспериментировать.
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={6}
                    mt={{ xs: 3, md: 0 }}
                    mb={{ xs: 5, md: 0 }}
                >
                    <img
                        className={classes.image}
                        src="/assets/images/183986.jpg"
                        alt={'размеры колец'}
                    />
                </Grid>
            </Grid>
            <Box
                mt={{ sx: 10, md: 25 }}
                py={{ xs: 3.2, md: 15 }}
                sx={{ backgroundColor: '#f5f3f3' }}
            >
                <Grid container>
                    <Grid item xs={isMobile ? 12 : 3} />
                    <Grid item xs={isMobile ? 12 : 6}>
                        <Typography
                            variant="h5"
                            align={isMobile ? 'left' : 'center'}
                            fontWeight={{ xs: 500, md: 400 }}
                            sx={{
                                textTransform: 'uppercase',
                            }}
                        >
                            как выбрать подходящий размер?
                        </Typography>
                        <Typography mt={{ xs: 3.2, md: 5 }} variant="body2">
                            Размерная сетка тонких колец до 5 мм шириной -
                            стандартная, однако, чем шире колечко, тем больше
                            вероятность в необходимости выбрать на 0,5 размера
                            больше. Также необходимо учитывать следующие
                            факторы:
                        </Typography>
                        <ul>
                            <li>
                                <Typography
                                    mt={{ xs: 1, md: 5 }}
                                    variant="body2"
                                >
                                    Пальцы увеличиваются или уменьшаются в
                                    объеме от жары или холода соответственно,
                                    отекают после спортивный упражнений;
                                </Typography>
                            </li>
                            <li>
                                <Typography mt={1} variant="body2">
                                    Объем средней фаланги может быть больше
                                    остальных, поэтому даже подходящее по
                                    размеру украшение может не подойти;
                                </Typography>
                            </li>
                        </ul>
                        <Typography mt={{ xs: 1, md: 5 }} variant="body2">
                            Важно! Любые методы измерения кольца без будущего
                            владельца или самостоятельно в домашних условиях не
                            дают гарантии в том, что украшение подойдет идеально
                            по размеру.
                        </Typography>
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 3} />
                </Grid>
            </Box>

            <Box mt={{ sx: 10, md: 25 }} py={{ xs: 4.8, md: 0 }} ref={myRef}>
                <Grid container>
                    <Grid item xs={isMobile ? 12 : 3} />
                    <Grid item xs={isMobile ? 12 : 6}>
                        <Typography
                            variant="h5"
                            fontWeight={{ xs: 500, md: 400 }}
                            align={isMobile ? 'left' : 'center'}
                            sx={{
                                textTransform: 'uppercase',
                            }}
                        >
                            таблица размеров
                        </Typography>
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 3} />
                </Grid>
            </Box>

            <Container
                sx={{
                    mt: { xs: 2.3, md: 10 },
                    mb: { xs: 0, md: 0 },
                }}
            >
                <Box sx={{ overflow: 'auto' }}>
                    <Grid container minWidth={'500px'}>
                        <Grid item xs={isMobile ? 12 : 2} />
                        <Grid item xs={isMobile ? 12 : 8}>
                            <Grid container spacing={2} mb={3}>
                                <HeaderRow text="Диаметр (мм)" />
                                <HeaderRow text="Длина окружности, которую вы измерили (мм)" />
                                <HeaderRow text="Размер кольца в России/Европе/Азии" />
                                <HeaderRow text="Размер кольца в США" />
                                <HeaderRow text="Размер кольца в Англии" />
                            </Grid>
                            {sizes.map((row, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        backgroundColor:
                                            index % 2 === 0
                                                ? '#F5F3F3'
                                                : 'transparent',
                                    }}
                                >
                                    <Grid container>
                                        {row.map((size, key) => (
                                            <TableRow key={key} text={size} />
                                        ))}
                                    </Grid>
                                </Box>
                            ))}
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 2} />
                    </Grid>
                </Box>
            </Container>
        </Container>
    )
}

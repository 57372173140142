import { DaDataAddress, DaDataSuggestion } from 'react-dadata'

export const initDefaultDadata = (
    value: string
): DaDataSuggestion<DaDataAddress> | undefined => {
    if (!value) return undefined
    return {
        value,
        unrestricted_value: '',
        data: {
            area: null,
            area_fias_id: null,
            area_kladr_id: null,
            area_type: null,
            area_type_full: null,
            area_with_type: null,
            beltway_distance: null,
            beltway_hit: null,
            block: null,
            block_type: null,
            block_type_full: null,
            federal_district: null,
            capital_marker: '0',
            city: null,
            city_area: null,
            city_district: null,
            city_district_fias_id: null,
            city_district_kladr_id: null,
            city_district_type: null,
            city_district_type_full: null,
            city_district_with_type: null,
            city_fias_id: null,
            city_kladr_id: null,
            city_type: null,
            city_type_full: null,
            city_with_type: null,
            country: '',
            country_iso_code: '',
            fias_id: '',
            fias_level: '',
            flat: null,
            flat_area: null,
            flat_price: null,
            flat_type: null,
            flat_type_full: null,
            geo_lat: null,
            geo_lon: null,
            geoname_id: null,
            history_values: null,
            house: null,
            house_fias_id: null,
            house_kladr_id: null,
            house_type: null,
            house_type_full: null,
            kladr_id: '',
            okato: null,
            oktmo: null,
            postal_box: null,
            postal_code: null,
            qc: null,
            qc_complete: null,
            qc_geo: null,
            qc_house: null,
            region: '',
            region_fias_id: '',
            region_kladr_id: '',
            region_type: '',
            region_type_full: '',
            region_with_type: '',
            settlement: null,
            settlement_fias_id: null,
            settlement_kladr_id: null,
            settlement_type: null,
            settlement_type_full: null,
            settlement_with_type: null,
            source: null,
            square_meter_price: null,
            street: null,
            street_fias_id: null,
            street_kladr_id: null,
            street_type: null,
            street_type_full: null,
            street_with_type: null,
            tax_office: null,
            tax_office_legal: null,
            timezone: null,
            unparsed_parts: null,
            fias_code: '',
            region_iso_code: '',
            fias_actuality_state: '',
            metro: null,
        },
    }
}

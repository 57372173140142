import { Box, Grid, SxProps, Theme, Typography } from '@mui/material'
import React from 'react'

const DolyameHowToPayItem: React.FC<{
    number: string
    upperText: string
    upperTextSxProps?: SxProps<Theme>
    loverText: string
    loverTextSxProps?: SxProps<Theme>
    lastItem?: true
}> = ({
    number,
    upperText,
    upperTextSxProps,
    loverText,
    loverTextSxProps,
    lastItem,
}) => (
    <Box
        width={{ xs: '100%' }}
        height={{ xs: 'auto', md: '236px' }}
        display="flex"
        flexDirection={{ xs: 'row', md: 'column' }}
        alignItems="top"
    >
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
            <Box
                minHeight="56px"
                minWidth="56px"
                sx={(theme) => ({
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: '56px',
                })}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Typography
                    variant="body2"
                    fontWeight="medium"
                    textAlign="center"
                    sx={(theme) => ({
                        color: theme.palette.primary.contrastText,
                    })}
                >
                    {number}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: lastItem ? 'none' : 'block',
                    width: { xs: '50%', md: '100%' },
                    minHeight: { xs: '76px', md: '50%' },
                    maxHeight: { md: '50%' },
                    mt: { xs: '10px', md: '0px' },
                    ml: { md: '30px' },
                    borderRight: { xs: '1px solid #DEDEDE', md: 'none' },
                    borderBottom: { md: '1px solid #DEDEDE' },
                }}
            />
        </Box>

        <Typography
            component="span"
            sx={{
                ml: { xs: '20px', md: '0px' },
                mt: { md: '24px' },
                fontSize: { xs: '14px', md: '18px' },
                fontWeight: 'medium',
            }}
        >
            <Typography
                sx={{
                    fontSize: 'inherit',
                    fontWeight: 'inherit',
                    ...upperTextSxProps,
                }}
            >
                {upperText}
            </Typography>
            <Typography
                sx={{
                    fontSize: 'inherit',
                    color: 'text.secondary',
                    mt: { xs: '8px', md: '16px' },
                    ...loverTextSxProps,
                }}
            >
                {loverText}
            </Typography>
        </Typography>
    </Box>
)

export const DolyameHowToPay: React.FC = () => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
                variant="h1"
                fontWeight="medium"
                sx={{ fontSize: { xs: '24px', md: '42px' } }}
            >
                Как оплатить «Долями»
            </Typography>

            <Grid
                container
                direction={{ xs: 'column', md: 'row' }}
                spacing={{ xs: '10px', md: '30px' }}
                mt={{ xs: '32px', md: '50px' }}
            >
                <Grid item xs={12} md={3}>
                    <DolyameHowToPayItem
                        number="1"
                        upperText="В оформлении заказа выберите способ оплаты Долями"
                        upperTextSxProps={{
                            maxWidth: { xs: '250px', md: '315px' },
                        }}
                        loverText="Доступно для заказов от 4990 ₽ до 30000 ₽"
                        loverTextSxProps={{ maxWidth: { xs: '240px' } }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DolyameHowToPayItem
                        number="2"
                        upperText="Укажите номер телефона и данные карты"
                        upperTextSxProps={{
                            maxWidth: { xs: '200px', md: '250px' },
                        }}
                        loverText="После оформления заказа"
                        loverTextSxProps={{ fontSize: { xs: '18px' } }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DolyameHowToPayItem
                        number="3"
                        upperText="Оплатите 25% стоимости покупки с помощью карты"
                        upperTextSxProps={{
                            maxWidth: { xs: '200px', md: '315px' },
                        }}
                        loverText="Подтвердите покупку и оплатите часть заказа картой"
                        loverTextSxProps={{
                            maxWidth: { xs: '240px', md: '315px' },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DolyameHowToPayItem
                        number="4"
                        upperText="Получите товар"
                        upperTextSxProps={{
                            maxWidth: { md: '100px' },
                        }}
                        loverText="Оставшиеся 3 части спишутся автоматически с шагом в две недели"
                        loverTextSxProps={{
                            maxWidth: { xs: '270px', md: '315px' },
                        }}
                        lastItem
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Modal } from 'app/components/Modal'
import { selectCart } from 'app/modules/Cart/slice/selectors'
import { selectLoyaltyData } from 'app/modules/Checkout/slice/selectors'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { ReactComponent as Checkbox } from '../../assets/Checkbox.svg'
import { ReactComponent as VectorRight } from '../../assets/VectorRight.svg'
import { LoyaltyDetail } from './LoyaltyDetail'
import { useOnlySales } from './useOnlySales'

export interface LoyaltyDetailState {
    succses: boolean
    onlyPromo?: true
    text?: string
}

export const LoyaltyDetailContainer: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const [loyaltyDetailModalIsOpen, setLoyaltyDetailModalIsOpen] =
        useState(false)
    const [loyaltyDetailState, setLoyaltyDetailState] =
        useState<LoyaltyDetailState>()

    const cart = useSelector(selectCart)
    const { data } = useSelector(selectLoyaltyData)
    const onlySales = useOnlySales()

    const handleModalClose = () => setLoyaltyDetailModalIsOpen(false)
    const handleModalOpen = () => {
        setLoyaltyDetailModalIsOpen(true)
    }

    useEffect(() => {
        if (onlySales) {
            setLoyaltyDetailState({
                succses: false,
                onlyPromo: true,
                text: 'Применить промокод',
            })
            return
        }

        if (cart?.attributes.mindbox_data?.spent_bonuses !== 0) {
            setLoyaltyDetailState({
                succses: true,
                text: `Бонусных рублей к списанию: ${cart?.attributes.mindbox_data?.spent_bonuses || 0}`,
            })
            return
        }

        if (Number(cart?.attributes.mindbox_data?.coupon_discount) !== 0) {
            setLoyaltyDetailState({
                succses: true,
                text: 'Промокод успешно применен',
            })
            return
        }

        setLoyaltyDetailState({ succses: false })
    }, [cart, onlySales])

    if (!cart) {
        return null
    }

    return (
        <>
            <Typography
                variant="h6"
                sx={{ fontSize: { md: '18px' } }}
                textTransform={{ xs: 'none', md: 'uppercase' }}
            >
                Получите скидку
            </Typography>

            <Box
                sx={{
                    border: '1px solid #DEDEDE',
                    borderRadius: '10px',
                    height: '92px',
                    width: '100%',
                    mt: { xs: '16px', md: '20px' },
                    px: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                }}
                onClick={handleModalOpen}
            >
                {loyaltyDetailState?.onlyPromo && (
                    <Box>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: { xs: '14px', md: '18px' },
                            }}
                            display="inline"
                            fontWeight="regular"
                            whiteSpace={{ md: 'nowrap' }}
                        >
                            {`${loyaltyDetailState?.text}`}
                        </Typography>
                    </Box>
                )}

                {loyaltyDetailState?.succses && (
                    <Box display="flex" sx={{ alignItems: 'center' }}>
                        <Box mr="16px" height="32px">
                            <Checkbox />
                        </Box>

                        <Typography
                            variant="body1"
                            style={{ color: theme.palette.primary.main }}
                            display={'inline'}
                            fontWeight="medium"
                        >
                            {`${loyaltyDetailState?.text}`}
                        </Typography>
                    </Box>
                )}
                {!(
                    loyaltyDetailState?.succses || loyaltyDetailState?.onlyPromo
                ) && (
                        <Box>
                            <Typography
                                variant="body1"
                                fontWeight="medium"
                                sx={{ fontSize: { xs: '14px', md: '18px' } }}
                            >
                                {isMobile
                                    ? 'Применить бонусы  или промокод'
                                    : 'Применить бонусные рубли или промокод'}
                            </Typography>
                            <Typography
                                variant="body2"
                                style={{ color: '#858585' }}
                                component="span"
                            >
                                Доступно
                                <Typography
                                    variant="body2"
                                    style={{ color: theme.palette.primary.main }}
                                    display={'inline'}
                                    fontWeight="medium"
                                >
                                    {` ${data.available_bonuses} бонусных рублей. `}
                                </Typography>
                            </Typography>
                        </Box>
                    )}
                <VectorRight />
            </Box>

            <Modal
                open={loyaltyDetailModalIsOpen}
                title={'Получите скидку'}
                handleClose={handleModalClose}
            >
                <LoyaltyDetail handleClose={handleModalClose} cart={cart} />
            </Modal>
        </>
    )
}

import { Box, Button, Typography } from '@mui/material'
import { ModalDialogSwicth } from 'app/components/Modal'
import { selectPaymentById } from 'app/modules/Payments/slice/selectors'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import OrderCompletedPopupImage from '../assets/OrderCompletedPopupImage.jpg'
import { selectOrders } from '../slice/selectors'

export const OrderComplitedDialog: React.FC = () => {
    const orders = useSelector(selectOrders)
    const getPayment = useSelector(selectPaymentById)

    const orderNumber = localStorage.getItem('cartOrderCompleted')

    const order = orders.find(
        (order) => order.attributes.number === orderNumber
    )

    const payment = getPayment(order?.relationships.payments.data[0]?.id)

    const [cartOrderCompleted, setCartOrderCompleted] = useState(false)

    const handleClose = () => {
        setCartOrderCompleted(false)
        localStorage.removeItem('cartOrderCompleted')
    }

    useEffect(
        function cartOrderCompletedOpener() {
            if (!order || !payment) return
            if (
                order.attributes.shipment_state !== 'pending' ||
                payment.attributes.payment_method_id === 2
            ) {
                setCartOrderCompleted(true)
            }
        },
        [order, payment]
    )

    return (
        <ModalDialogSwicth
            open={cartOrderCompleted}
            height="auto"
            closeIconColor="white"
            title={
                <Box
                    sx={{
                        width: '100%',
                        height: { xs: '61.333333vw', md: '307px' },
                        mt: { xs: '-30px', md: '-34px' },
                        mb: { xs: '-22px' },
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            position: 'absolute',
                            objectFit: 'cover',
                            width: 'inherit',
                            height: 'inherit',
                        }}
                        src={OrderCompletedPopupImage}
                    />
                </Box>
            }
            handleClose={handleClose}
        >
            <Box
                mt="50px"
                display="flex"
                flexDirection="column"
                alignItems="center"
                width={{ xs: '91.5%', md: '400px' }}
                mx={{ xs: 'auto', md: '50px' }}
            >
                <Typography
                    variant="h3"
                    fontSize={{ xs: '18px', md: '24px' }}
                    textTransform="uppercase"
                    fontWeight="medium"
                >
                    спасибо за покупку ❤
                </Typography>

                <Typography
                    variant="body1"
                    fontSize={{ xs: '14px', md: '18px' }}
                    textAlign="center"
                    mt={{ xs: '12px', md: '24px' }}
                >
                    Ваш заказ №{orderNumber} успешно оформлен. В ближайшее время
                    мы свяжемся с вами для уточнения деталей.
                </Typography>

                <Button
                    variant="contained"
                    fullWidth
                    sx={{ height: '60px', mt: { xs: '50px', md: '45px' } }}
                    onClick={handleClose}
                >
                    ОК
                </Button>
            </Box>
        </ModalDialogSwicth>
    )
}

import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    Link,
    Snackbar,
    Typography,
    useTheme,
} from '@mui/material'
import React, { useState } from 'react'

export const DesktopCookieInfoDialog: React.FC = () => {
    const theme = useTheme()
    const [open, setOpen] = useState<boolean>(true)

    const handleClose = () => {
        setOpen(false)
        localStorage.setItem('cookie_confirmed', 'true')
    }

    return (
        <Snackbar
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            sx={{
                background: theme.palette.background.paper,
            }}
        >
            <Box
                sx={{
                    width: '418px',
                    height: '144px',
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                }}
            >
                <DialogContent sx={{ px: 2, pt: 2, overflow: 'hidden' }}>
                    <DialogContentText lineHeight="1">
                        <Typography variant="body3">
                            Посещая наш веб-сайт, Вы соглашаетесь на обработку
                            cookies, обработка которых осуществляется в
                            соответствии с{' '}
                            <Link
                                rel="noreferrer"
                                href="/personal-data-policy.pdf"
                                target="_blank"
                            >
                                Политикой обработки персональных данных
                            </Link>
                            .
                        </Typography>
                    </DialogContentText>
                </DialogContent>

                <DialogActions sx={{ pl: 2, pb: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            pl: 1.75,
                            width: '145px',
                            height: '36px',
                        }}
                        onClick={handleClose}
                    >
                        <Typography variant="body2" fontWeight={500}>
                            Принять
                        </Typography>
                    </Button>
                </DialogActions>
            </Box>
        </Snackbar>
    )
}

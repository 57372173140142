import { mindboxViewCategory } from 'app/features/MindBox'
import {
    selectCategories,
    selectCategoryByPermalink,
    selectHeaderCategories
} from "app/modules/Categories/slice/selectors"
import { CategoryBanner } from 'app/modules/Categories/templates/CategoryBanner'
import ProductPageContextProvider from 'app/modules/Products/components/ProductsFilter/hooks'
import { ProductCardsList } from 'app/modules/Products/templates/ProductCardsList'
import { selectAccountWholesale } from 'app/modules/Account/slice/selectors'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {useHistory, useLocation} from "react-router-dom"

export const CategoryPage: React.FC = () => {
    const { pathname } = useLocation()

    const permalink = pathname.substring(1)

    const categories = useSelector(selectCategories)
    const getCategory = useSelector(selectCategoryByPermalink)
    const wholesale = useSelector(selectAccountWholesale)
    let category = getCategory(permalink)

    const headerCategories = useSelector(selectHeaderCategories)
    const router = useLocation()
    let currentCategory = headerCategories.find(i => i.attributes.permalink === router.pathname.slice(1))

    useEffect(() => {
        if (router.pathname === "/category") {
            category = categories.find(i => i.id === "1")
            currentCategory = categories.find(i => i.id === "1")
        }
    }, [router, category])

    useEffect(() => {
        if (category) mindboxViewCategory(category, wholesale)
    }, [category?.id])

    if (!category) return <CategoryBanner />

    return (
        <ProductPageContextProvider category={currentCategory || category}>
            <CategoryBanner category={currentCategory} />

            <ProductCardsList />
        </ProductPageContextProvider>
    )
}

import { useMediaQuery, useTheme } from '@mui/material'
import { selectCurrentCityPickup } from 'app/modules/Cities/slice/selectors'
import { ReactComponent as TruckBadgetDesktop } from 'app/modules/Products/assets/TruckBadgetDesktop.svg'
import { ReactComponent as TruckBadgetMobile } from 'app/modules/Products/assets/TruckBadgetMobile.svg'
import React from 'react'
import { useSelector } from 'react-redux'
import {IProduct} from "types/IProduct"

interface I {
    product: IProduct
}
export const TruckBadget: React.FC<I> = ({product}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    if (!product.attributes.delivery) return null

    if (isMobile) return <TruckBadgetMobile />

    return <TruckBadgetDesktop />
}

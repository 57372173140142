import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types'

import { cityAdapter } from '.'

const { selectAll, selectById } = cityAdapter.getSelectors()

const selectDomain = (state: RootState) => state.cities

export const selectStatus = createSelector(
    [selectDomain],
    (state) => state.status
)

export const selectForm = createSelector([selectDomain], (state) => state.form)

export const selectCities = createSelector([selectDomain], (state) =>
    selectAll(state)
)

export const selectCityOpened = createSelector(
    [selectDomain],
    (state) => state.is_modal_open
)

export const selectCitiesByCountry = createSelector(
    [selectDomain],
    (state) => (id?: string) =>
        selectAll(state).filter(
            (city) =>
                city.relationships.country.data?.id === id ||
                (!id && !city.relationships.country.data?.id)
        )
)

export const selectCurrentShopCity = createSelector([selectDomain], (state) =>
    selectById(state, state.current_shop_city_id)
)

export const selectCurrentCity = createSelector([selectDomain], (state) =>
    selectById(state, state.current_city_id)
)

export const selectCurrentCityPickup = createSelector(
    [selectDomain],
    (state) =>
        selectById(state, state.current_city_id)?.attributes.pickup || false
)

export const selectCurrentCityID = createSelector(
    [selectDomain],
    (state) => state.current_city_id
)

import {Box, FormGroup} from "@mui/material"
import {SALE_CATEGORY_ID} from "app/modules/Categories"
import {
  selectCategories,
  selectChildrenCategories,
  selectHeaderCategories,
} from "app/modules/Categories/slice/selectors"
import {selectFiltersParamsPrices} from "app/modules/Products/slice/selectors"
import React, {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import {useLocation, useHistory} from "react-router-dom"

import {useCategoryPageContext} from "../../hooks"
import {ProductsFilterKind} from "../../ProductsFilterKind"
import {ProductsFilterFormControlLabel} from "./ProductsFilterFormControlLabel"

export const ProductsFilterCategory: React.FC = () => {
  const {filterReadAdapter, category, isSaleCategory, handleTaxonsChange} =
    useCategoryPageContext()

  const categories = useSelector(selectCategories)
  const headerCategories = useSelector(selectHeaderCategories)


  const filterCategories = isSaleCategory ?
    headerCategories : categories.find(i => i.id === "1")?.categories || []


  if (filterCategories.length === 0) return null
  return (
    <ProductsFilterKind name="Категория">
      <FormGroup>
        {filterCategories
        .filter((category) => category.id !== SALE_CATEGORY_ID && category.id !== "1")
        .map((category) => {
          return (
            <Box
              key={category.id}
              sx={{
                mb: {xs: "12px", lg: "2px"},
                height: {xs: "24px", md: "28px"},
              }}
            >
              <ProductsFilterFormControlLabel
                labelText={category.attributes.name}
                checked={
                  filterReadAdapter?.taxons?.includes(
                    category.id
                  ) || false
                }
                onChange={(event) => {
                  const checked = event.target.checked
                  handleTaxonsChange(category.id, checked)
                }}
              />
            </Box>
          )
        })}
      </FormGroup>
    </ProductsFilterKind>
  )
}

import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/material'
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha'
import { PhoneField } from 'app/components/PhoneField'
import { testPhoneNumber } from 'app/components/PhoneField/utils/validatePhoneNumber'
import { useYandexTranslateContext } from 'app/features/YandexTranslate'
import { authActions } from 'app/modules/Auth/slice'
import {
    selectCreateAccountForm,
    selectStatus,
} from 'app/modules/Auth/slice/selectors'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EStatus } from 'types'
import {
    googleAnalyticsEvent,
    googleAnalyticsEvents,
} from 'utils/googleAnalytics'
import * as yup from 'yup'

export const PhoneForm: React.FC = () => {
    const dispatch = useDispatch()
    const [capchaSuccess, setCapchaSuccess] = useState(false)
    const [visible, setVisible] = useState(false)

    const { data, status: signup_status } = useSelector(selectCreateAccountForm)
    const status = useSelector(selectStatus)

    const { yaTranslateGetCode } = useYandexTranslateContext()

    const validationSchema = yup.object({
        user: yup.object({
            phone: yup.string().required().test(testPhoneNumber),
        }),
    })

    const formik = useFormik({
        validationSchema,
        initialValues: data,
        validateOnBlur: false,
        validateOnChange: false,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (capchaSuccess) {
                googleAnalyticsEvent(googleAnalyticsEvents.input_phone)
                dispatch(authActions.getCode({ requestData: values }))
            } else {
                setVisible(true)
            }
        },
    })

    useEffect(() => {
        dispatch(authActions.resetStatus())
    }, [])

    useEffect(() => {
        if (signup_status === EStatus.FINISHED) {
            dispatch(authActions.resetPhoneStatus())
        }
    }, [signup_status])

    return (
        <Box
            noValidate
            component="form"
            onSubmit={(e: React.FormEvent) => {
                e.preventDefault()

                formik.handleSubmit()
            }}
            sx={{
                width: '100%',
            }}
        >
            <PhoneField
                fullWidth
                name="user.phone"
                label="Номер телефона"
                value={formik.values.user.phone || ''}
                error={!!formik.errors.user?.phone}
                onChange={formik.handleChange}
            />
            <LoadingButton
                loading={status === EStatus.PENDING}
                fullWidth
                size="large"
                color={'primary'}
                variant="contained"
                sx={{ mt: 5 }}
                onClick={() => {
                    formik.handleSubmit()
                }}
            >
                Продолжить
            </LoadingButton>
            <div style={{ marginTop: '20px' }}>
                <InvisibleSmartCaptcha
                    sitekey="ysc1_Fe6sep3luHaRw9A2B4gIn1fUhdCBeCbOyzLcJzGu0edfb244"
                    language={yaTranslateGetCode() as 'ru' | 'en'}
                    onChallengeVisible={() => setCapchaSuccess(false)}
                    onSuccess={() => {
                        setCapchaSuccess(true)
                        setVisible(false)
                        formik.handleSubmit()
                    }}
                    visible={visible}
                />
            </div>
        </Box>
    )
}

import 'react-dadata/dist/react-dadata.css'

import { TabContext, TabPanel } from '@mui/lab'
import { Box, CardMedia, Tabs, TextField, Typography } from '@mui/material'
import { selectCurrentCityPickup } from 'app/modules/Cities/slice/selectors'
import React, { forwardRef, useRef } from 'react'
import { AddressSuggestions } from 'react-dadata'
import { useSelector } from 'react-redux'
import { ShippingMethodType } from 'types/ICustomerInformation'

import { selectCartDisabledDeliveryStates } from '../../slice/selectors'
import { CustomerInformationChildProps } from './CustomerInformationForm'
import { DeliveryStateTab } from './DeliveryStateTab'

export const DeliveryStateForm: React.FC<CustomerInformationChildProps> = ({
    formik,
    shop,
    shopImage,
    daDataSuggestionAddress,
    setDaDataSuggestionAddress,
    handleShippingMethodIdChange,
    setIsAddressChanged,
}) => {
    const disabledDeliveryStates = useSelector(selectCartDisabledDeliveryStates)
    const currentCityPickup = useSelector(selectCurrentCityPickup)
    const addressFieldRef = useRef<HTMLInputElement>()

    return (
        <TabContext value={formik.values.order.delivery_state}>
            <Tabs
                value={formik.values.order.delivery_state}
                onChange={(event, value) => {
                    handleShippingMethodIdChange(value)
                }}
                sx={{
                    border: '1px solid #DEDEDE',
                    borderRadius: '10px',
                    height: {
                        xs: '96px',
                        md: '108px',
                    },
                    '& .MuiTabs-flexContainer': {
                        height: '100%',
                    },
                    '& .MuiTabs-indicator': {
                        display: 'none',
                    },
                }}
            >
                <DeliveryStateTab
                    value={ShippingMethodType.pickup}
                    text="Самовывоз из магазина"
                    disabled={disabledDeliveryStates.includes(
                        ShippingMethodType.pickup
                    )}
                    checked={
                        formik.values.order.delivery_state ===
                        ShippingMethodType.pickup
                    }
                />
                <DeliveryStateTab
                    value={ShippingMethodType.courier}
                    text="Курьерская доставка"
                    subtext={
                        currentCityPickup ? undefined : 'Доставим из Москвы'
                    }
                    disabled={disabledDeliveryStates.includes(
                        ShippingMethodType.courier
                    )}
                    checked={
                        formik.values.order.delivery_state ===
                        ShippingMethodType.courier
                    }
                />
            </Tabs>

            <TabPanel
                value={ShippingMethodType.pickup}
                sx={{ px: 0, py: { xs: '40px', md: '60px' } }}
            >
                <Typography
                    variant="h6"
                    sx={{ fontSize: { xs: '14px', md: '18px' } }}
                >
                    ЗАБЕРУ ИЗ МАГАЗИНА
                </Typography>
                <Box display="flex" sx={{ mt: { xs: '24px', md: '20px' } }}>
                    <CardMedia
                        component="img"
                        sx={{
                            minWidth: { xs: '36.26vw', md: '240px' },
                            maxWidth: '240px',
                            minHeight: { xs: '26.93vw', md: '180px' },
                            maxHeight: '180px',
                            borderRadius: '10px',
                            objectFit: 'cover',
                        }}
                        src={shopImage}
                    />

                    <Box
                        display="flex"
                        sx={{
                            ml: '20px',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{ fontSize: { xs: '14px', md: '18px' } }}
                        >
                            {shop?.attributes.name}
                        </Typography>
                        <Box mt={{ xs: '8px', md: '12px' }}>
                            <Typography
                                variant="body3"
                                component="p"
                                color="grey.600"
                                sx={{
                                    lineHeight: { xs: '16px', md: '28px' },
                                    fontSize: { xs: '12px', md: '18px' },
                                }}
                            >
                                {shop?.attributes.address}
                            </Typography>
                            <Typography
                                variant="body3"
                                component="p"
                                color="grey.600"
                                mt="4px"
                                sx={{
                                    lineHeight: { xs: '16px', md: '28px' },
                                    fontSize: { xs: '12px', md: '18px' },
                                }}
                            >
                                {shop?.attributes.working_hours}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </TabPanel>
            <TabPanel
                value={ShippingMethodType.courier}
                sx={{ px: 0, py: { xs: '40px', md: '60px' } }}
            >
                <Typography
                    variant="h6"
                    sx={{ fontSize: { xs: '14px', md: '18px' } }}
                >
                    ДОСТАВИТЬ ПО АДРЕСУ
                </Typography>

                <Box
                    sx={{ height: '60px', mb: { xs: '44px', md: '50px' } }}
                    pt="24px"
                >
                    <AddressSuggestions
                        token="cad82b45299c5853695728dbf53ea46743f977fa"
                        defaultQuery={
                            formik.values.order.ship_address_attributes.address1
                        }
                        value={daDataSuggestionAddress}
                        onChange={(e) => {
                            setDaDataSuggestionAddress(e)
                            setIsAddressChanged(false)
                        }}
                        selectOnBlur
                        customInput={forwardRef((props, ref) => (
                            <TextField
                                fullWidth
                                inputRef={ref}
                                ref={addressFieldRef}
                                variant="standard"
                                label="Город, улица, дом, квартира"
                                error={
                                    !!formik.errors.order
                                        ?.ship_address_attributes?.address1
                                }
                                {...props}
                                onChange={(e) => {
                                    props.onChange(e)
                                    setIsAddressChanged(true)
                                    // инпут теряет фокус при изменении state
                                    setTimeout(() => {
                                        const inputElem =
                                            addressFieldRef.current?.getElementsByTagName(
                                                'input'
                                            )[0]
                                        if (inputElem) inputElem.focus()
                                    })
                                }}
                            />
                        ))}
                    />
                </Box>

                <TextField
                    fullWidth
                    variant="standard"
                    label="Комментарий для курьера"
                    name="order.special_instructions"
                    value={formik.values.order.special_instructions || ''}
                    error={
                        !!formik.errors.order?.ship_address_attributes?.comment
                    }
                    onChange={formik.handleChange}
                />
            </TabPanel>
        </TabContext>
    )
}

import { Box, CardMedia, useMediaQuery, useTheme } from '@mui/material'
import { AutoplayVideo } from 'app/components/AutoplayVideo'
import React from 'react'
import { IBanner } from 'types/IBanner'

const MainBannerContentMedia: React.FC<{ src: string; isVideo: boolean }> = ({
    src,
    isVideo,
}) => {
    if (isVideo) {
        return <AutoplayVideo src={src} />
    }

    return (
        <CardMedia
            sx={{
                objectFit: 'cover',
                width: 'inherit',
                height: 'inherit',
            }}
            src={src}
            component="img"
        />
    )
}

export const BannerContent: React.FC<{ content: IBanner }> = ({ content }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    return (
        <Box
            sx={{
                position: 'relative',
                height: '100%',
                width: '100%',
            }}
        >
            {content.attributes.url && (
                <Box
                    component="a"
                    href={content.attributes.url}
                    sx={{
                        position: 'absolute',
                        bgcolor: 'transparent',
                        height: '100%',
                        width: '100%',
                        zIndex: 1,
                    }}
                />
            )}
            <Box
                sx={{
                    bgcolor: 'grey.500',
                    height: 'inherit',
                    maxHeight: 'inherit',
                    width: 'inherit',
                    overflow: 'hidden',
                    zIndex: -1,
                }}
            >
                {isMobile ? (
                    <MainBannerContentMedia
                        src={content.attributes.attachment_mobile_url}
                        isVideo={content.attributes.attachment_mobile_video}
                    />
                ) : (
                    <MainBannerContentMedia
                        src={content.attributes.attachment_url}
                        isVideo={content.attributes.attachment_video}
                    />
                )}
            </Box>
        </Box>
    )
}

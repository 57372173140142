import { ChevronRight as ChevronRightIcon } from '@mui/icons-material'
import { Box, Container } from '@mui/material'
import { selectCurrentCity } from 'app/modules/Cities/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { MobileMenuItem } from '../MobileMenuItem'
import { SocialNetsBlock } from '../SocialNetsBlock/SocialNetsBlock'
import GlobeIcon from './assets/globe_icon.svg'
import MappinIcon from './assets/mappin_icon.svg'
import TelephoneIcon from './assets/telephone_icon.svg'

export const ContactBlock: React.FC = () => {
    const currentCity = useSelector(selectCurrentCity)

    return (
        <Container>
            <MobileMenuItem
                icon={GlobeIcon}
                text={currentCity?.attributes.name || ''}
            />
            <Link to={'/store'}>
                <MobileMenuItem
                    icon={MappinIcon}
                    text={'магазины'}
                    endContent={<ChevronRightIcon />}
                />
            </Link>
            <MobileMenuItem
                icon={TelephoneIcon}
                text={
                    <Box
                        sx={{ display: 'flex' }}
                        rel="noreferrer"
                        href="tel:+78002012323"
                        component="a"
                    >
                        8 (800) 201-23-23
                    </Box>
                }
            />
            <SocialNetsBlock />
        </Container>
    )
}

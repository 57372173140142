import { Box, Typography } from '@mui/material'
import { Modal } from 'app/components/Modal'
import React from 'react'

import { useCategoryPageContext } from '../hooks'
import { ProductsFiltersList } from '../ProductsFiltersList'

export const MobileProductsFilter: React.FC = () => {
    const { filterModalOpen, closeFilterModal } = useCategoryPageContext()

    return (
        <Modal
            open={filterModalOpen}
            handleClose={closeFilterModal}
            title={
                <Typography
                    sx={{
                        fontWeight: 500,
                        textTransform: 'uppercase',
                    }}
                    variant="h5"
                    ml={{ xs: '16px', md: '30px' }}
                >
                    фильтры
                </Typography>
            }
            height={'calc(100% - 32px)'}
        >
            <Box
                sx={{
                    ml: { md: '30px' },
                    mt: { md: '20px' },
                    mr: { md: '30px' },
                }}
            >
                <ProductsFiltersList />
            </Box>
        </Modal>
    )
}

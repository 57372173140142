import { FormGroup } from '@mui/material'
import React from 'react'

import { useCategoryPageContext } from '../../hooks'
import { ProductsFilterKind } from '../../ProductsFilterKind'
import { ProductsFilterFormControlLabel } from './ProductsFilterFormControlLabel'

export const ProductsFilterOnlySale: React.FC = () => {
    const { filterReadAdapter, isSaleCategory, handleSaleChange } =
        useCategoryPageContext()

    if (isSaleCategory) return null

    return (
        <ProductsFilterKind name="Акции">
            <FormGroup>
                <ProductsFilterFormControlLabel
                    labelText="Скидки"
                    checked={filterReadAdapter.sale || false}
                    onChange={() => handleSaleChange()}
                />
            </FormGroup>
        </ProductsFilterKind>
    )
}

import { Box, Divider, Grid, Paper, Typography } from '@mui/material'
import { LoyaltyTable } from 'app/components/LoyaltyTable/LoyaltyTable'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { convertDiscountLevel } from 'utils/convertUtils'

import { accountActions } from '../slice'
import { selectAccountLoyalty } from '../slice/selectors'

export const AccountGift: React.FC = () => {
    const dispatch = useDispatch()

    const loyalty = useSelector(selectAccountLoyalty)

    useEffect(() => {
        dispatch(accountActions.getLoyalty())
    }, [])

    return (
        <Paper
            sx={{
                width: '100%',
                pb: 7.5,
                pt: 5.625,
            }}
            elevation={0}
        >
            <Box
                sx={{
                    px: 6.25,
                }}
            >
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <Box
                            sx={{
                                background:
                                    'linear-gradient(90deg, #E9DEFA 0%, #FBFCDB 100%)',
                                borderRadius: '16px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                p: 3.75,
                            }}
                        >
                            <Typography
                                variant="body1"
                                fontWeight={'normal'}
                                sx={{ textTransform: 'uppercase' }}
                            >
                                Бонусный счет
                            </Typography>

                            <Typography
                                mt={5}
                                mb={6.75}
                                variant="h2"
                                fontWeight={500}
                                sx={{
                                    textTransform: 'uppercase',
                                }}
                            >
                                {loyalty.available.toLocaleString()}
                            </Typography>

                            <Box
                                sx={{
                                    backgroundColor: '#fff',
                                    borderRadius: '999px',
                                    px: 3.5,
                                    height: '32px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    fontWeight={500}
                                    sx={{ textTransform: 'uppercase' }}
                                >
                                    {convertDiscountLevel(loyalty.discount)}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={1} />
                    <Grid item xs={12} md={6}>
                        <Typography
                            mt={3.75}
                            fontWeight={500}
                            variant="body1"
                            sx={{
                                textTransform: 'uppercase',
                            }}
                        >
                            БОНУСНЫЕ РУБЛИ – внутренняя
                        </Typography>

                        <Typography
                            fontWeight={500}
                            variant="body1"
                            sx={{
                                textTransform: 'uppercase',
                            }}
                        >
                            валюта Вселенной Island Soul.
                        </Typography>

                        <Typography mt={3.75} variant="body2">
                            Вы получаете до 12% бонусных рублей за покупки в
                            зависимости от вашего уровня, и можете использовать
                            их при оплате до 45% стоимости товаров.
                            <br />
                            <br />1 бонусный рубль = 1 ₽
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Divider sx={{ my: 7.5 }} />

            <Box
                sx={{
                    px: 6.25,
                }}
            >
                {!!loyalty?.next_level_sum && (
                    <Box
                        sx={{
                            display: {
                                md: 'flex',
                            },
                            mb: 5,
                        }}
                    >
                        <Typography
                            fontWeight={500}
                            variant="h5"
                            sx={{ textTransform: 'uppercase' }}
                        >
                            До следующего уровня
                        </Typography>

                        <Typography
                            fontWeight={500}
                            ml={{ md: 1 }}
                            color={'primary'}
                            variant="h5"
                            sx={{ textTransform: 'uppercase' }}
                        >
                            {`${loyalty.next_level_sum.toLocaleString()} ₽`}
                        </Typography>
                    </Box>
                )}

                <LoyaltyTable />
            </Box>
        </Paper>
    )
}

import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { DesktopCookieInfoDialog } from './DesktopCookieInfoDialog'
import { MobileCookieInfoDialog } from './MobileCookieInfoDialog'

export const CookieInfoDialog: React.FC = () => {
    const theme = useTheme()

    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    const isCookieConfirmed =
        localStorage.getItem('cookie_confirmed') === 'true' || false

    return (
        <>
            {isDesktop && !isCookieConfirmed && <DesktopCookieInfoDialog />}

            {isMobile && !isCookieConfirmed && <MobileCookieInfoDialog />}
        </>
    )
}

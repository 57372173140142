import {
    Avatar,
    Box,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import React from 'react'

import { ReactComponent as TelegramIcon } from './assets/telegram_icon.svg'
import { ReactComponent as VKIcon } from './assets/vk_icon.svg'
import { ReactComponent as WhatsAppIcon } from './assets/whatsapp_icon.svg'
import { ReactComponent as YoutubeIcon } from './assets/youtube_icon.svg'

export const ArticleAdCard: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))

    return (
        <Box
            height={isMobile ? '346px' : `calc(78vh)`}
            sx={{
                p: 2.5,
                bgcolor: 'grey.200',
                mt: {
                    xs: 5,
                    lg: 0,
                },
                mb: {
                    xs: 5,
                    lg: 4,
                },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    mt: {
                        xs: -10,
                    },
                }}
            >
                <Typography
                    variant={isMobile ? 'h6' : 'h5'}
                    fontWeight={400}
                    color={theme.palette.text.primary}
                    width="80%"
                >
                    Soul life — это журнал о жизни, любви, энергии и счастье —
                    об Island Soul
                </Typography>
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    mt={{ xs: 2.5, lg: 4 }}
                >
                    <Avatar
                        alt="Ксения Казанцева"
                        src="/assets/images/kseniya.jpg"
                    />
                    <Box ml={2}>
                        <Typography
                            variant={isMobile ? 'body2' : 'body1'}
                            fontWeight={400}
                        >
                            Ксения Казанцева
                        </Typography>
                        <Typography
                            variant={isMobile ? 'body3' : 'body2'}
                            fontWeight={400}
                            color={theme.palette.text.disabled}
                        >
                            Главный редактор журнала
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box
                sx={{
                    position: 'absolute',
                    bottom: {
                        xs: 25,
                        lg: 40,
                    },
                }}
            >
                <Typography
                    variant="body2"
                    fontWeight={400}
                    color={theme.palette.text.primary}
                >
                    Подпишись на нас в социальных сетях
                </Typography>

                <Stack
                    sx={{ mt: 1 }}
                    spacing={3}
                    direction="row"
                    alignItems="center"
                >
                    <Box
                        sx={{ display: 'flex' }}
                        rel="noreferrer"
                        href="https://m.vk.com/public211205674"
                        target="_blank"
                        component="a"
                    >
                        <VKIcon />
                    </Box>

                    <Box
                        sx={{ display: 'flex' }}
                        rel="noreferrer"
                        href="https://t.me/+6n-0K5F5BblhNjVi"
                        target="_blank"
                        component="a"
                    >
                        <TelegramIcon />
                    </Box>

                    <Box
                        sx={{ display: 'flex' }}
                        rel="noreferrer"
                        href="https://wa.me/79999718174"
                        target="_blank"
                        component="a"
                    >
                        <WhatsAppIcon />
                    </Box>

                    <Box
                        sx={{ display: 'flex' }}
                        rel="noreferrer"
                        href="https://www.youtube.com/channel/UCtHo8K1aLV2nd-Xdi3J4eTQ"
                        target="_blank"
                        component="a"
                    >
                        <YoutubeIcon />
                    </Box>
                </Stack>
            </Box>
        </Box>
    )
}

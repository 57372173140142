import { Button, Typography } from '@mui/material'
import React from 'react'
import { IVariant } from 'types/IVariant'

import { getVarianSize } from './VariantsList'

interface SizeItemVariantProps {
    variant: IVariant
    selectedVariant: string | null
    setSelectedVariant: (newValue: string) => void
}

export const SizeItemVariant: React.FC<SizeItemVariantProps> = ({
    variant,
    selectedVariant,
    setSelectedVariant,
}) => {
    const sizeText = getVarianSize(variant)

    return (
        <Button
            variant="outlined"
            value={variant.id}
            disabled={
                !variant?.attributes.available_in_current_city ||
                !variant?.attributes.in_stock
            }
            sx={(theme) => ({
                borderRadius: '10px',
                color: theme.palette.primary.dark,
                borderColor: '#DEDEDE',
                boxSizing: 'border-box',
                padding: '5px 2px',
                height: { xs: '11.73333vw', sm: '58px' },
                minWidth: { xs: '13vw', sm: '68px' },
                width: { xs: '13vw', sm: '68px' },
                '&:hover': {
                    backgroundColor: `${theme.palette.primary.main}14`,
                    color: theme.palette.primary.main,
                },
                '&.MuiButton-outlinedSuccess': {
                    backgroundColor: `${theme.palette.primary.main}14`,
                    color: theme.palette.primary.main,
                    border: '2px solid',
                },
            })}
            color={selectedVariant === variant.id ? 'success' : 'primary'}
            onClick={() => {
                setSelectedVariant(variant.id)
            }}
        >
            <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                {sizeText}
            </Typography>
        </Button>
    )
}

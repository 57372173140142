import {Button, Container, Typography} from "@mui/material"
import {Box} from "@mui/system"
import {selectCurrentCity} from "app/modules/Cities/slice/selectors"
import { ReactComponent as Location } from 'app/modules/Products/assets/location_icon.svg'
import { ReactComponent as Delivery } from 'app/modules/Products/assets/DeliveryDesktop.svg'
import { ReactComponent as Shop } from 'app/modules/Products/assets/store.svg'
import { ReactComponent as Warning } from 'app/modules/Products/assets/warning.svg'
import {selectShopByCityId} from "app/modules/Shops/slice/selectors"
import moment from "moment"
import "moment/locale/ru"
import React, {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import {IProduct, IProductShops} from "types/IProduct"
import {request} from "utils/request"

interface I {
  product: IProduct
  selectedVariant?: string | null
  setOpenedMap:  React.Dispatch<React.SetStateAction<boolean>>
  shopVariants: IProductShops[] | null
}


export function ShopsInfo({product, selectedVariant, shopVariants, setOpenedMap}: I) {

  moment().locale("ru")
  const [delivery, setDelivery] = useState<number>(0)

  const [count, setCount] = useState<number>()

  const getShops = useSelector(selectShopByCityId)
  const currentCity = useSelector(selectCurrentCity)

  useEffect(() => {
    request(`/api/v2/storefront/cities/calculate_delivery`)
    .then(res => setDelivery(res.calendar_min))
    .catch(() => null)
  }, [product])

  useEffect(() => {
    setCount(getShops(currentCity?.id).filter(i => shopVariants?.find(item => +i.id === item.attributes.shop_id)).length)
  }, [shopVariants])
  const shopCountText = (itemCount: number) => {
    const itemCountLastDigit = itemCount % 10
    const itemCountDecimalDigit = Math.floor(itemCount / 10)

    if (itemCountDecimalDigit === 1) return 'магазинов'

    switch (itemCountLastDigit) {
      case 1:
        return 'магазина'
      case 2:
      case 3:
      case 4:
        return 'магазинов'
      default:
        return 'магазинов'
    }
  }

  return (
    <>
      <Box
        sx={{
          pt: "30px!important"
        }}
      >
        <Container
          sx={{
            pl: "0px!important",
            pr: "0px!important"
          }}
        >
          <Button
            disabled={!selectedVariant}
            sx={{
              color: '#000000',
              border: '1px solid #C1C1C1',
              display: 'flex',
              padding: "19px 23px",
              gap: "19px",
              justifyContent: 'flex-start',
              '&:hover': { border: "1px solid #C1C1C1", background: "#EAEAEAFF" },
            }}
            fullWidth
            size="large"
            variant="outlined"
            onClick={() => setOpenedMap(true)}
          >
            <Location stroke="#C1C1C1"  width="18px" height="22px"/>
              <Typography
                sx={{
                  fontWeight: 'normal',
                  textTransform: "none"
                }}
              >
                Наличие в магазинах
              </Typography>
          </Button>
        </Container>
      </Box>

      {selectedVariant && shopVariants ? (
        <Container
          sx={{
            pt: "30px!important"
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              gap: "17px"
            }}
          >
            <Shop stroke="#C1C1C1"  width="26px" height="26px"/>
            <Typography
              sx={{
                fontWeight: 'normal',
                textTransform: "none"
              }}
            >
              Забрать сейчас из <Box component="span" sx={{color: "#54A67F", cursor: "pointer"}}
                                     onClick={() => selectedVariant && setOpenedMap(true)}
            >{count} {count && shopCountText(count)}</Box>, бесплатно
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              gap: "17px",
              mt: "24px"
            }}
          >
            <Delivery stroke="#C1C1C1" width="26px" height="26px"/>
            <Typography
              sx={{
                fontWeight: 'normal',
                textTransform: "none"
              }}
            >
              Доставка товара с {moment().add( delivery ,"d").format("D MMM")}, бесплатно
            </Typography>
          </Box>
        </Container>
      ) : (
        <Container
          sx={{
            pt: "30px!important",
            px: '0!important'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              background: "#F5F3F3",
              justifyContent: 'flex-start',
              alignItems: "center",
              gap: "16px",
              py: "16px",
              px: "24px"
            }}
          >
            <Warning width="24px" height="24px"/>
            <Typography  fontSize="14px"
                         fontWeight="regular"
                         textTransform="none">
              Выберите размер, чтобы увидеть доступные способы доставки
            </Typography>
          </Box>
        </Container>
      )}
    </>
  )
}

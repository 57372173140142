import { ICartItemResponse } from 'types/ICart'
import { ILineItem } from 'types/ILineItem'
import { IProduct } from 'types/IProduct'
import { IVariant } from 'types/IVariant'
import {
    isILineItem,
    isIProduct,
    isIVariant,
} from 'utils/json-api/resourceCheckers'

import { mindBoxOperation, productInfo } from '.'

const SET_CART_OPERATION_NAME = 'Website.SetCart'
const RESET_CART_OPERATION_NAME = 'ResetKorzina1ItemList'

export const mindboxSetCart = (response: ICartItemResponse, wholesale: boolean) => {
    if (!response.data.relationships.line_items.data.length) {
        return mindboxResetCart(wholesale)
    }

    const productsComposition = response.data.relationships.line_items.data
        .map((lineItemIdentifier) => {
            const lineItem = response.included
                .filter(isILineItem)
                .find((included) => included.id === lineItemIdentifier.id)

            const variant = response.included
                .filter(isIVariant)
                .find(
                    (included) =>
                        included.id === lineItem?.relationships.variant.data?.id
                )

            const product = response.included
                .filter(isIProduct)
                .find(
                    (included) =>
                        included.id === variant?.relationships.product.data?.id
                )

            if (lineItem && variant && product)
                return { lineItem, variant, product }
            return null
        })
        .filter(Boolean) as {
            lineItem: ILineItem
            variant: IVariant
            product: IProduct
        }[]

    const productList = productsComposition.map((item) => {
        const lineItemInfo = {
            count: item.lineItem.attributes.quantity,
            pricePerItem: item.lineItem.attributes.price,
        }

        return {
            ...productInfo({ product: item.product, variant: item.variant }),
            ...lineItemInfo,
        }
    })

    mindBoxOperation({
        operation: SET_CART_OPERATION_NAME,
        data: {
            productList,
        },
        wholesale: wholesale,
    })
}

const mindboxResetCart = (wholesale: boolean) => {
    mindBoxOperation({
        operation: RESET_CART_OPERATION_NAME,
        data: {},
        wholesale: wholesale,
    })
}

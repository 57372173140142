import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import moment from 'moment'
import { EStatus } from 'types'
import { ILoyalty } from 'types/ILoyalty'
import { IUser, IUserItemResponse, IUserParams } from 'types/IUser'

import { IAccountState } from './types'

export const accountAdapter = createEntityAdapter<IUser>()

const initialUser: IUser = {
    id: '',
    type: 'user',
    attributes: {
        email: '',
        store_credits: 0,
        completed_orders: 0,
        gender: null,
        firstname: '',
        lastname: '',
        phone: '',
        city: '',
        birthday: 0,
        personal_data: false,
        newsletter_agreement: false,
        wholesale: false,
        birthday_date: null,
        birthday_time: null,
    },
    relationships: {
        default_billing_address: {
            data: null,
        },
        default_shipping_address: {
            data: null,
        },
        avatar_blob: {
            data: null,
        },
    },
}

const slice = createSlice({
    name: 'account',
    initialState: accountAdapter.getInitialState<IAccountState>({
        ids: [],
        entities: {},
        status: EStatus.INITIAL,
        loyalty: {
            status: EStatus.INITIAL,
            data: {
                discount: 0,
                customer_id: 0,
                customer_firstname: '',
                customer_lastname: '',
                orders_sum: 0,
                next_level_sum: 0,
                available: 0,
            },
        },
        disabled: false,
        form: {
            data: initialUser,
        },
    }),
    reducers: {
        accountLoaded(state, action: PayloadAction<IUserItemResponse>) {
            accountAdapter.setAll(state, [action.payload.data])
            state.form.data = action.payload.data
            state.form.data.attributes.birthday_date = action.payload.data
                .attributes.birthday
                ? moment.unix(action.payload.data.attributes.birthday).format()
                : null
            state.form.data.attributes.birthday_time = action.payload.data
                .attributes.birthday
                ? moment.unix(action.payload.data.attributes.birthday).format()
                : null
            state.status = EStatus.FINISHED
        },
        getLoyalty(state) {
            state.loyalty.status = EStatus.PENDING
        },
        setLoyalty(state, action: PayloadAction<ILoyalty>) {
            state.loyalty.status = EStatus.FINISHED
            state.loyalty.data = action.payload
        },
        updateAccount(state, _action: PayloadAction<IUserParams>) {
            state.status = EStatus.PENDING
        },
        statusError(state) {
            state.status = EStatus.ERROR
        },
        loyaltyStatusError(state) {
            state.loyalty.status = EStatus.ERROR
        },
        disabledAccountModal(state) {
            state.disabled = true
        },
        activeAccountModal(state) {
            state.disabled = false
        },
        logout(state) {
            accountAdapter.removeAll(state)
        },
    },
})

export const { actions: accountActions, reducer: accountReducer } = slice

import {LoadingButton} from "@mui/lab"
import {
  Box, Button,
  Container,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material"
import {selectCurrentCity} from "app/modules/Cities/slice/selectors"
import {ReactComponent as Close} from "app/modules/Products/assets/close.svg"
import {selectShopByCityId} from "app/modules/Shops/slice/selectors"
import React, {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import {Clusterer, Map, Placemark, YMaps, ZoomControl} from "react-yandex-maps"
import {IProductShops} from "types/IProduct"
import {IShop} from "types/IShop"

interface I {
  openedMap: boolean
  setOpenedMap:  React.Dispatch<React.SetStateAction<boolean>>
  shopVariants: IProductShops[] | null
}
export function ShopsMap ({openedMap, setOpenedMap, shopVariants  }: I) {

  const [selected, setSelected] = useState<null | IShop>(null)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

  const [isCtrl, setCtrl] = useState<boolean>(false)

  const shopCountText = (itemCount: number) => {
    const itemCountLastDigit = itemCount % 10
    const itemCountDecimalDigit = Math.floor(itemCount / 10)

    if (itemCountDecimalDigit === 1) return 'единиц'

    switch (itemCountLastDigit) {
      case 1:
        return 'единица'
      case 2:
      case 3:
      case 4:
        return 'единицы'
      default:
        return 'единиц'
    }
  }

  const currentCity = useSelector(selectCurrentCity)
  const getShops = useSelector(selectShopByCityId)
  const currentCityCenter = () => {
    return [
      parseFloat(currentCity?.attributes.lat || '54'),
      parseFloat(currentCity?.attributes.lng || '78'),
    ]
  }

  const currentCityZoom = () => {
    return currentCity ? 10 : 4
  }

  const getShopCoordinates = (shop: IShop) => {
    return [
      parseFloat(shop.attributes.lat),
      parseFloat(shop.attributes.lng),
    ]
  }

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]

    if (body) {
      body.onkeydown = callbackDown
      body.onkeyup = callbackUp
    }

    return () => {
      if (body) {
        body.onkeydown = null
        body.onkeyup = null
      }
    }
  }, [])

  const callbackDown = (e: any) => {
    if (e.keyCode === 17) {
      setCtrl(true)
    }
  }
  const callbackUp = (e: any) => {
    if (e.keyCode === 17) {
      setCtrl(false)
    }
  }

  return (
    <Drawer
      open={openedMap}
      onClose={() => setOpenedMap(false)}
      anchor="right"
    >
      <Container
        sx={{
          height: "auto",
          width: "1100px",
          p: "0!important"
      }}
      >
        <Box sx={{
          height: "14vh",
          width: "100%",
          display: "flex",
          pt: "34px",
          pb: "30px",
          px: "30px",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "500",
              textTransform: 'uppercase',
            }}
          >
            выберите магазин для самовывоза
          </Typography>
          <Close onClick={() => setOpenedMap(false)}/>
        </Box>

        <Container
          sx={{
            p: "0!important",
            display: "flex",
          }}
        >
          <YMaps>
            <Map
             defaultOptions={{suppressMapOpenBlock: true}}
              modules={["geocode", "SuggestView"]}
              width="60%"
              height="86vh"
              state={{
                center: currentCityCenter(),
                zoom: currentCityZoom(),
              }}
              instanceRef={(ref: any) => {
                if (ref && ref.behaviors && isCtrl) {
                  ref.behaviors.enable(['scrollZoom'])
                } else if (ref && ref.behaviors && !isCtrl) {
                  ref.behaviors.disable(['scrollZoom'])
                }
              }}
            >
              <ZoomControl />

              <Clusterer
                options={{
                  preset: 'islands#blueClusterIcons',
                  groupByCoordinates: false,
                  minClusterSize: 2,
                  gridSize: 70,
                }}
              >
                {getShops(currentCity?.id).filter(i => shopVariants?.find(item => +i.id === item.attributes.shop_id)).map((shop) => (
                  <Placemark
                    key={shop.id}
                    geometry={getShopCoordinates(shop)}
                    onClick={() => setSelected(shop)}
                    options={{
                      preset: 'islands#blueShoppingCircleIcon',
                    }}
                    defaultProperties={{
                      hintContent: `<b>Island Soul</b><br />${shop.attributes.working_hours}`,
                      balloonContentHeader: 'Island Soul',
                      balloonContentBody: `${shop.attributes.name}<br />${shop.attributes.working_hours}`,
                    }}
                    modules={[
                      'geoObject.addon.balloon',
                      'geoObject.addon.hint',
                    ]}
                  />
                ))}
              </Clusterer>
            </Map>
          </YMaps>

          <Box sx={{width: "40%",}}>
            <List
              sx={{
                "&::-webkit-scrollbar": {
                  display: "none"
                },
                borderTop: "1px solid var(--divider, #DEDEDE)",
                borderBottom: "1px solid var(--divider, #DEDEDE)",
                overflowY: "auto",
                height: "75vh",
                p: 0,
              }}
            >
              {getShops(currentCity?.id).filter(i => shopVariants?.find(item => +i.id === item.attributes.shop_id))
              .map((shop) => {
                const countOnHand = shopVariants?.find(item => +shop.id === item.attributes.shop_id)?.attributes.count_on_hand
                return (
                  <ListItem
                    key={shop.id}
                    sx={{
                      p: 0
                    }}
                  >
                    <ListItemButton
                      onClick={() => setSelected(shop)}
                      selected={shop.id === selected?.id}
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid var(--divider, #DEDEDE)",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "10px",
                        px: "30px",
                        py: "20px",
                        "&.Mui-selected": {
                          backgroundColor: "#F5F3F3"
                        },
                        "&.Mui-focusVisible": {
                          backgroundColor: "#F5F3F3"
                        },
                        ":hover": {
                          backgroundColor: "#F5F3F3"
                        }
                      }}
                    >
                      <Typography sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        textTransform: "uppercase"
                      }}>{shop.attributes.name}</Typography>
                      <Typography sx={{fontSize: "14px", color: "#757575"}}>{shop.attributes.address}</Typography>
                      <Typography sx={{fontSize: "14px", color: "#757575"}}>{shop.attributes.working_hours}</Typography>
                      <Typography sx={{fontSize: "14px", color: "#757575"}}>В наличии
                        <Box component="span" sx={{color: "#54A67F"}}> {countOnHand} {countOnHand && shopCountText(countOnHand)} товара</Box>
                      </Typography>

                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
            <Box sx={{
              height: "9.5vh",
              display: "flex",
              px: "20px",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <LoadingButton
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => null}
                disabled={!selected}
                // loading={addItemStatus === EStatus.PENDING}
                sx={{
                  height: '70%',
                }}
              >
                К оформлению
              </LoadingButton>
            </Box>
          </Box>
        </Container>


      </Container>
    </Drawer>
  )
}

import {
    Box,
    Button,
    Container,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { LoyaltyTable } from 'app/components/LoyaltyTable/LoyaltyTable'
import { authActions } from 'app/modules/Auth/slice'
import { selectAuthStatus } from 'app/modules/Auth/slice/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

const useStyles = makeStyles(() =>
    createStyles({
        image: {
            width: '100%',
            height: 'auto',
        },
    })
)

export const LoyaltyProgram: React.FC = () => {
    const dispatch = useDispatch()

    const classes = useStyles()
    const history = useHistory()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const { isAuthorized, notAuthorized } = useSelector(selectAuthStatus)

    const handleOpenProfile = () => {
        if (notAuthorized) {
            dispatch(authActions.setReferrerUrl(''))
            dispatch(authActions.resetPhone())
            dispatch(authActions.setModalStatus('phone'))
        }
        if (isAuthorized) {
            history.push('/profile')
        }
    }

    return (
        <Container
            sx={{
                mt: { md: 30, lg: 20, xl: 17 },
            }}
            disableGutters={isMobile}
        >
            <Grid
                container
                sx={{
                    mt: 1,
                }}
            >
                <Grid
                    item
                    xs={12}
                    lg={6}
                    display={'flex'}
                    alignItems={'center'}
                >
                    <Box px={{ lg: 12, xs: 3, md: 6 }}>
                        <img
                            src="/assets/images/bg-is-stamp-loop2.png"
                            alt={'bg-is-stamp-loop'}
                        />
                        <Typography
                            variant="h4"
                            fontWeight={500}
                            mt={7}
                            sx={{
                                textTransform: 'uppercase',
                            }}
                        >
                            программа лояльности
                        </Typography>

                        <Typography variant="body2" mt={4}>
                            Cделайте всего одну покупку, чтобы получить доступ к
                            системе лояльности Island Soul.
                            <br />
                            Вы получите до 12% кэшбека бонусными рублями и
                            можете использовать их при оплате до 45% стоимости
                            товаров.
                        </Typography>

                        <Box display={'flex'} mt={4}>
                            <Typography variant="body2" fontWeight={500}>
                                Телефон горячей линии:
                            </Typography>

                            <Box href="tel:+79773448016" component={'a'} ml={1}>
                                <Typography
                                    color={'primary'}
                                    variant="body2"
                                    fontWeight={400}
                                >
                                    +7 977 344 80 16
                                </Typography>
                            </Box>
                        </Box>

                        <Button
                            size="large"
                            fullWidth={isMobile}
                            color="primary"
                            variant="contained"
                            sx={{
                                my: 6,
                                textTransform: 'uppercase',
                            }}
                            onClick={handleOpenProfile}
                        >
                            Зарегистрироваться
                        </Button>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={{
                        mx: { xs: 3, md: 0 },
                    }}
                >
                    <img
                        className={classes.image}
                        src="/assets/images/Frame1896.jpg"
                        alt={'Программа лояльности'}
                    />
                </Grid>
            </Grid>
            <Container>
                <Typography
                    variant="h4"
                    fontWeight={500}
                    mt={20}
                    sx={{
                        textTransform: 'uppercase',
                    }}
                >
                    как устроена программа
                </Typography>
                <Typography variant="body2" mt={5}>
                    В программе лояльности 4 уровня: Silver, Gold, Platinum,
                    Diamond.
                    <br />
                    Уровень зависит от суммы покупок.
                    <br />
                    <br />
                    Ниже вы можете ознакомиться с уровнями подробнее:
                </Typography>

                <Box
                    mt={6}
                    px={{
                        xs: 0,
                        md: 4,
                    }}
                    py={2.5}
                    sx={{
                        border: {
                            md: '1px solid #DEDEDE',
                        },
                    }}
                >
                    <LoyaltyTable />
                </Box>
            </Container>
        </Container>
    )
}

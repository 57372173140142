import { Box, Slider, Stack, TextField } from '@mui/material'
import {SALE_CATEGORY_ID} from "app/modules/Categories"
import {selectChildrenCategories, selectHeaderCategories} from "app/modules/Categories/slice/selectors"
import { selectFiltersParamsPrices } from 'app/modules/Products/slice/selectors'
import { useDebounce } from 'hooks/useDebounce'
import React, {useEffect, useState} from "react"
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useCategoryPageContext } from '../../hooks'
import { ProductsFilterKind } from '../../ProductsFilterKind'

export const ProductsFilterPrice: React.FC = () => {
    const location = useLocation()
    const filterParamsPrices = useSelector(selectFiltersParamsPrices)

    const headerCategories = useSelector(selectHeaderCategories)
    const router = useLocation()

    const currentCategory = headerCategories.find(i => i.attributes.permalink === router.pathname.slice(1))

    const { filterReadAdapter, isSaleCategory, handleDeleteFilter, handleClearFiltersWithoutTaxons, category,  handleChangeFilter, handleChangePrice, handleClearFilter } =
        useCategoryPageContext()

    const getSubCategories = useSelector(selectChildrenCategories)


    const filterCategories = isSaleCategory
      ? headerCategories
      : getSubCategories(category.id)

    const [filterPrices, setFilterPrices] = React.useState<[number, number]>([
        filterReadAdapter.price?.[0] && filterReadAdapter.price?.[0] !== null ? filterReadAdapter.price[0] : filterParamsPrices.min_price,
        !!filterReadAdapter.price?.[1] && filterReadAdapter.price?.[1] !== null ? filterReadAdapter.price?.[1] : filterParamsPrices.max_price,
    ])

    /** В значения данной переменной попадает последнее значение состояния filterPrices
     * которое не менялось в течении одной секунды */
    const debouncedFilterPrices = useDebounce(filterPrices, 1000)

    useEffect(() => {
        const filterMaxPriceNotDefined = !filterReadAdapter.price?.[1]

        if (
            debouncedFilterPrices[0] === filterParamsPrices.min_price &&
            debouncedFilterPrices[1] === filterParamsPrices.max_price &&
            filterMaxPriceNotDefined
        )
            return

        const category = headerCategories.find(i => i.id === router.search.split("=")[1])

        if (category?.attributes.permalink.split("/")[0] === "catalog" && debouncedFilterPrices[1] === category.attributes.filters?.price.max) {
            return
        }

        handleChangePrice({
            priceFrom: debouncedFilterPrices[0],
            priceTo: debouncedFilterPrices[1],
        })
    }, [debouncedFilterPrices[0], debouncedFilterPrices[1]])

    useEffect(() => {
        if (filterReadAdapter.price) {
            setFilterPrices([
                filterReadAdapter.price?.[0] && filterReadAdapter.price?.[0] !== null ? filterReadAdapter.price[0] :
                  filterParamsPrices.min_price,
                !!filterReadAdapter.price?.[1] && filterReadAdapter.price?.[1] !== null ? filterReadAdapter.price?.[1] :
                  filterParamsPrices.max_price,
            ])
        } else {
            setFilterPrices([
                filterParamsPrices.min_price,
                filterParamsPrices.max_price,
            ])
        }
    }, [filterReadAdapter.price?.[0], filterReadAdapter.price?.[1], filterParamsPrices.min_price, filterParamsPrices.max_price])

    useEffect(() => {
        if (!filterReadAdapter.price?.length) {
            setFilterPrices([
                filterParamsPrices.min_price,
                filterParamsPrices.max_price,
            ])
        }
    }, [filterParamsPrices.min_price, filterParamsPrices.max_price])

    const handleChangeFilterPrices = (
        _event: Event,
        newValue: number | number[]
    ) => {
        if (!Array.isArray(newValue)) {
            return
        }

        setFilterPrices(newValue as [number, number])
    }

    return (
        <ProductsFilterKind name="Цена">
            <Stack spacing={3.75} direction="row">
                <TextField
                    label="От"
                    variant="standard"
                    value={filterPrices[0]}
                    onChange={(e) => {
                        const priceFrom = parseInt(e.target.value, 10)

                        setFilterPrices((previousValue) => [
                            isNaN(priceFrom)
                                ? filterParamsPrices.min_price
                                : priceFrom,
                            previousValue[1],
                        ])
                    }}
                />

                <TextField
                    label="До"
                    variant="standard"
                    value={filterPrices[1]}
                    onChange={(e) => {
                        const priceTo = parseInt(e.target.value, 10)

                        setFilterPrices((previousValue) => [
                            previousValue[0],
                            isNaN(priceTo)
                                ? filterParamsPrices.max_price
                                : priceTo,
                        ])
                    }}
                />
            </Stack>

            {filterParamsPrices.min_price !== filterParamsPrices.max_price && (
                <Box
                    sx={{
                        mt: { xs: '24px', md: '16px' },
                    }}
                >
                    <Slider
                        value={filterPrices}
                        onChange={handleChangeFilterPrices}
                        valueLabelDisplay="auto"
                        max={filterParamsPrices.max_price || 50000}
                        min={filterParamsPrices.min_price}
                        step={5}
                        sx={{
                            color: 'unset',
                            ' .MuiSlider-rail': {
                                height: '2px',
                            },
                            ' .MuiSlider-track': {
                                height: '2px',
                            },
                            ' .MuiSlider-thumb': {
                                height: '14px',
                                width: '14px',
                            },
                        }}
                    />
                </Box>
            )}
        </ProductsFilterKind>
    )
}

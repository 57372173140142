import { Grid, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { BoxLoyalty, LoyaltyItem } from './BoxLoyalty'

export const LoyaltyTable: React.FC = () => {
    const loyaltyItems: LoyaltyItem[] = [
        {
            title: '',
            sale: 'Начисление бонусов',
            details: [
                'Скидка бонусами',
                'Условия получения',
                'Бонусы в день рождения',
                'Привилегии Island Soul Nail&Brow Bar',
            ],
        },
        {
            title: 'SILVER',
            sale: '5%',
            details: [
                'До 30% оплаты бонусами',
                'Регистрация на сайте или заполнение анкеты',
                'Дополнительно 10%',
                'Скидки на услуги салона 15%',
            ],
        },
        {
            title: 'GOLD',
            sale: '7%',
            details: [
                'До 35% оплаты бонусами',
                'От суммы покупок 100 000 ₽',
                'Дополнительно 10%',
                'Скидки на услуги салона 20%',
            ],
        },
        {
            title: 'PLATINUM',
            sale: '10%',
            details: [
                'До 40% оплаты бонусами',
                'От суммы покупок 250 000 ₽',
                'Дополнительно 10%',
                'Скидки на услуги салона 30%',
            ],
        },
        {
            title: 'DIAMOND',
            sale: '12%',
            details: [
                'До 45% оплаты бонусами',
                'От суммы покупок 500 000 ₽',
                'Дополнительно 12%',
                'Бесплатная процедура в салоне раз в месяц',
            ],
        },
    ]

    const theme = useTheme()
    const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'))
    let changedLoyaltyItems: LoyaltyItem[] = []

    if (isTablet) {
        changedLoyaltyItems = loyaltyItems.slice(1)
    } else {
        changedLoyaltyItems = loyaltyItems
    }

    return (
        <>
            <Grid container spacing={{ xs: 2, md: 0 }}>
                {changedLoyaltyItems.map((loyaltyItem, index) => (
                    <Grid item xs={12} md key={index}>
                        <BoxLoyalty
                            left={index === 0 && !isTablet}
                            item={loyaltyItem}
                            labelItem={loyaltyItems[0]}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

import { Box, Container, useMediaQuery, useTheme } from '@mui/material'
import { ExperienceBanner } from 'app/components/ExperienceBanner'
import { MindboxTarget } from 'app/components/MinboxContactForm'
import { MainArticleCardsList } from 'app/modules/Articles/templates/MainArticleCardsList'
import { CategoryCardsList } from 'app/modules/Categories/templates/CategoryCardsList'
import { ProductGiftsSlider } from 'app/modules/Products/templates/ProductGiftsSlider'
import { NoveltyProductListSliderContainer } from 'app/modules/Products/templates/ProductListSliderContainer'
import { ProductSaleListSliderContainer } from 'app/modules/Products/templates/ProductSaleListSliderContainer'
import React from 'react'

import { FamilyBanner } from './components/FamilyBanner'
import { MainBanner } from './components/MainBanner'

export const MainPageLayout: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'))

    return (
        <>
            <MainBanner />
            <Box
                sx={{
                    mt: { xs: 3, lg: '80px' },
                    px: { xs: 0, md: '2.34375vw' },
                }}
            >
                <NoveltyProductListSliderContainer
                    showTitle={!isMobile}
                    title={'Тренд'}
                />
            </Box>
            <Box
                sx={{
                    mt: { xs: 9, lg: '80px' },
                    px: { xs: 0, md: '2.34375vw' },
                }}
            >
                <ProductGiftsSlider />
            </Box>
            {(isMobile || isTablet) && (
                <Container
                    sx={{
                        mx: { xs: '4.2vw', sm: 2 },
                        px: { xs: 0, sm: 0 },
                        mt: { xs: '42px' },
                        width: 'auto',
                    }}
                >
                    <CategoryCardsList />
                </Container>
            )}

            <Box sx={{ mt: { xs: 9, md: '80px' } }}>
                <ExperienceBanner />
            </Box>

            {!isMobile && (
                <Box sx={{ mt: 15 }}>
                    <FamilyBanner />
                </Box>
            )}
            <Box sx={{ mt: { xs: 9, lg: '80px' } }}>
                <ProductSaleListSliderContainer />
            </Box>
            <Box
                sx={{
                    mt: { xs: '40px', md: '100px' },
                }}
            >
                <MainArticleCardsList />
            </Box>
            <MindboxTarget
                popmechanicId="57321"
                sx={{
                    mt: { xs: '40px', md: '50px' },
                    mb: { xs: -5, md: -20 },
                }}
            />
        </>
    )
}

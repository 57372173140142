import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { selectCategoryById } from 'app/modules/Categories/slice/selectors'
import { chunk } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ProductSlider } from '../components/ProductSlider'
import { productsActions } from '../slice'
import { selectGiftProducts } from '../slice/selectors'

const GIFT_CATEGORY_ID = '157'

export const ProductGiftsSlider: React.FC = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'lg'))

    const giftProducts = useSelector(selectGiftProducts)

    const isShowSlider = giftProducts.length >= 3

    const giftCategory = useSelector(selectCategoryById)(GIFT_CATEGORY_ID)

    useEffect(() => {
        dispatch(
            productsActions.loadGiftProducts({
                taxons: GIFT_CATEGORY_ID,
                perPage: 100,
            })
        )
    }, [])

    const productsPages = React.useMemo(() => {
        const chunkSize = 2
        const chunks = chunk(giftProducts, chunkSize)

        if (giftProducts.length % chunkSize === 0) {
            return chunks
        } else {
            const lastChunk = chunks.pop() || []
            const firstChunk = chunks.find(Boolean) || []

            chunks.push(
                lastChunk.concat(
                    firstChunk.slice(0, chunkSize - lastChunk.length)
                )
            )

            return chunks
        }
    }, [giftProducts])

    if (!isShowSlider) return null

    return (
        <>
            <Box sx={{ mb: '45px' }}>
                <Typography
                    gutterBottom
                    sx={{ textTransform: 'uppercase' }}
                    variant={isMobile ? 'h6' : 'h4'}
                    textAlign="center"
                    fontWeight="medium"
                >
                    {giftCategory?.attributes.name || ''}
                </Typography>
            </Box>

            <ProductSlider productsPages={productsPages} isMobile={isMobile} />
        </>
    )
}

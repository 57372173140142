import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import {RecoilRoot} from "recoil"
import { store } from 'store/configureStore'
import { ThemeProvider } from 'styles/theme/ThemeProvider'

import { App } from './app'
import reportWebVitals from './reportWebVitals'

Sentry.init({
    dsn: 'https://c755c0815e084c2d9af181b95dc805d9@sentry.islandsouljewelry.com/4',
    integrations: [new BrowserTracing()],
})

const tagManagerArgs = {
    gtmId: 'GTM-W3G24WH',
}

if (process.env.NODE_ENV === 'production') {
    TagManager.initialize(tagManagerArgs)
}

ReactDOM.render(
    <Provider store={store}>
      <RecoilRoot>
        <ThemeProvider>
              <HelmetProvider>
                  {/* <React.StrictMode> */}
                  <App />
                  {/* </React.StrictMode> */}
              </HelmetProvider>
          </ThemeProvider>
        </RecoilRoot>
    </Provider>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

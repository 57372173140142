import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types'

const selectDomain = (state: RootState) => state.checkout

export const selectPartnerInformation = createSelector(
    [selectDomain],
    (state) => state.forms.partnerInformation
)

export const selectCustomerInformation = createSelector(
    [selectDomain],
    (state) => state.forms.customerInformation
)

export const selectPaymentsShipmentsAttributes = createSelector(
    [selectDomain],
    (state) => state.forms.paymentsShipmentsAttributes
)

export const selectLoyaltyData = createSelector(
    [selectDomain],
    (state) => state.forms.loyalty
)

export const selectWhichPromoUsed = createSelector(
    [selectDomain],
    (state) => state.forms.loyalty.data.whichPromoUsed
)

export const selectPaymentMethods = createSelector(
    [selectDomain],
    (state) =>
        state.forms.paymentsShipmentsAttributes.data.order.payments_attributes
)

import { PayloadAction } from '@reduxjs/toolkit'
import { cartActions } from 'app/modules/Cart/slice'
import { selectCartToken } from 'app/modules/Cart/slice/selectors'
import { imagesActions } from 'app/modules/Images/slice'
import { listItemsActions } from 'app/modules/LineItems/slice'
import { variantsActions } from 'app/modules/Variants/slice'
import { AxiosRequestConfig } from 'axios'
import { toast } from 'react-toastify'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { ICartItemResponse } from 'types/ICart'
import { ICustomerInformation } from 'types/ICustomerInformation'
import { IPartnerInformation } from 'types/IPartnerInformation'
import { IPayment } from 'types/IPayment'
import { IPaymentMethodCollectionResponse } from 'types/IPaymentMethod'
import {
    IPaymentsAttributes,
    IPaymentsResponseAttributes,
} from 'types/IPaymentsShipmentsAttributes'
import { errorDictionary } from 'utils/errorDictionary'
import { request } from 'utils/request'

import { checkoutActions } from '.'
import { setCustomerInformation, setShipmentsAttributes } from './dataSetters'
import { LoyaltyPromoType } from './types'

const getCartItemResponseParams = (token: string): AxiosRequestConfig<any> => {
    return {
        method: 'PATCH',
        params: {
            include: [
                'line_items',
                'variants',
                'variants.images',
                'billing_address',
                'shipping_address',
                'user',
                'payments',
                'shipments.shipping_rates',
                'promotions',
            ].join(','),
        },
        headers: {
            'X-Spree-Order-Token': token,
            'Content-Type': 'application/vnd.api+json',
        },
    }
}

function* handleError(error: any) {
    yield put(checkoutActions.statusError())

    toast.error(error.data.error || 'Что-то пошло не так', {
        type: 'error',
    })
}

export function* checkoutNext() {
    try {
        const token: string = yield select(selectCartToken)

        const response: ICartItemResponse = yield call(
            request,
            '/api/v2/storefront/checkout/next',
            getCartItemResponseParams(token)
        )

        yield setShipmentsAttributes(response)

        yield setCustomerInformation(response)
        yield put(checkoutActions.setAvailableBonuses(response.data.attributes.available_bonuses))
        yield put(checkoutActions.setMindBoxData(response.data.attributes.mindbox_data))
        yield put(variantsActions.variantsLoaded(response))
        yield put(imagesActions.imagesAdded(response))
        yield put(listItemsActions.listItemsLoaded(response))
        yield put(cartActions.cartCheckoutLoaded(response))
    } catch (error: any) {
        yield handleError(error)
    }
}

export function* checkoutPrevious() {
    try {
        const token: string = yield select(selectCartToken)

        const response: ICartItemResponse = yield call(
            request,
            '/api/v2/storefront/checkout/previous',
            getCartItemResponseParams(token)
        )
        yield setCustomerInformation(response)
        yield put(checkoutActions.setAvailableBonuses(response.data.attributes.available_bonuses))
        yield put(checkoutActions.setMindBoxData(response.data.attributes.mindbox_data))
        yield put(variantsActions.variantsLoaded(response))
        yield put(imagesActions.imagesAdded(response))
        yield put(listItemsActions.listItemsLoaded(response))
        yield put(cartActions.cartCheckoutLoaded(response))
    } catch (error: any) {
        yield handleError(error)
    }
}

export function* loadPaymentMethods() {
    try {
        const token: string = yield select(selectCartToken)

        const response: IPaymentMethodCollectionResponse = yield call(
            request,
            '/api/v2/storefront/checkout/payment_methods',
            {
                headers: {
                    'X-Spree-Order-Token': token,
                    'Content-Type': 'application/vnd.api+json',
                },
            }
        )

        const payment_methods: IPaymentsAttributes[] = response.data
            .filter((item) => item.type === 'payment_method')
            .map((item) => {
                return {
                    payment_method_id: item.id,
                    ...item.attributes,
                }
            })
        yield put(checkoutActions.setPaymentsAttributes(payment_methods))
    } catch (error: any) {
        yield handleError(error)
    }
}

export function* applyBonuses(action: PayloadAction<number>) {
    try {
        const token: string = yield select(selectCartToken)
        const requestURL = `/api/v2/storefront/cart/apply_bonuses`

        const response: ICartItemResponse = yield call(request, requestURL, {
            method: 'PATCH',
            params: {
                include: ['promotions'].join(','),
            },
            data: {
                bonuses: parseFloat(action.payload.toString()),
            },
            headers: {
                'X-Spree-Order-Token': token,
                'Content-Type': 'application/json; charset=utf-8',
            },
        })

        yield put(cartActions.cartCheckoutLoaded(response))
        yield put(
            checkoutActions.setBonuses(response.data.attributes.promo_total)
        )
        yield put(checkoutActions.setAvailableBonuses(response.data.attributes.available_bonuses))
        yield put(checkoutActions.setMindBoxData(response.data.attributes.mindbox_data))

        if (action.payload !== 0) {
            yield put(
                checkoutActions.setWhichPromoUsed(LoyaltyPromoType.bonuses)
            )
        } else {
            yield put(checkoutActions.setWhichPromoUsed(LoyaltyPromoType.none))
        }
    } catch (error: any) {
        yield handleError(error)
    }
}

export function* applyPromocode(action: PayloadAction<string>) {
    try {
        const token: string = yield select(selectCartToken)
        const requestURL = `/api/v2/storefront/cart/apply_coupon_code`

        const response: ICartItemResponse = yield call(request, requestURL, {
            method: 'PATCH',
            params: {
                include: ['promotions'].join(','),
            },
            data: {
                coupon_code: action.payload,
            },
            headers: {
                'X-Spree-Order-Token': token,
                'Content-Type': 'application/json; charset=utf-8',
            },
        })

        yield put(cartActions.cartCheckoutLoaded(response))

        yield put(
            checkoutActions.setPromocode(response.data.attributes.promo_total)
        )
        yield put(checkoutActions.setAvailableBonuses(response.data.attributes.available_bonuses))
        yield put(checkoutActions.setMindBoxData(response.data.attributes.mindbox_data))
        yield put(checkoutActions.setWhichPromoUsed(LoyaltyPromoType.promocode))
        yield put(checkoutActions.setPromocodeError(''))
    } catch (error: any) {
        yield put(checkoutActions.setPromocode(''))
        yield put(
            checkoutActions.setPromocodeError(errorDictionary(error.data.error))
        )
    }
}

export function* deletePromocode() {
    try {
        const token: string = yield select(selectCartToken)
        const requestURL = `/api/v2/storefront/cart/remove_coupon_code`

        const response: ICartItemResponse = yield call(request, requestURL, {
            method: 'DELETE',
            params: {
                include: ['promotions'].join(','),
            },
            headers: {
                'X-Spree-Order-Token': token,
                'Content-Type': 'application/json; charset=utf-8',
            },
        })

        yield put(cartActions.cartCheckoutLoaded(response))
        yield put(
            checkoutActions.setPromocode(response.data.attributes.promo_total)
        )
        yield put(checkoutActions.setAvailableBonuses(response.data.attributes.available_bonuses))
        yield put(checkoutActions.setMindBoxData(response.data.attributes.mindbox_data))
        yield put(checkoutActions.setWhichPromoUsed(LoyaltyPromoType.none))
        yield put(checkoutActions.setPromocodeError(''))
    } catch (error: any) {
        yield put(checkoutActions.setPromocode(''))
        yield put(
            checkoutActions.setPromocodeError(errorDictionary(error.data.error))
        )
    }
}

export function* updateCustomerInformation(
    action: PayloadAction<ICustomerInformation>
) {
    try {
        const token: string = yield select(selectCartToken)

        const response: ICartItemResponse = yield call(
            request,
            '/api/v2/storefront/checkout',
            {
                method: 'PATCH',
                params: {
                    include: ['billing_address', 'shipping_address'].join(','),
                },
                data: action.payload,
                headers: {
                    'X-Spree-Order-Token': token,
                    'Content-Type': 'application/vnd.api+json',
                },
            }
        )

        yield put(cartActions.cartUpdated(response.data))
        yield setCustomerInformation(response)
        yield put(checkoutActions.customerInformationUpdated())
        yield put(checkoutActions.loadCheckoutNext())
    } catch (error: any) {
        yield handleError(error)
    }
}

export function* confirmDeliveryMethod() {
    try {
        yield put(checkoutActions.loadCheckoutNext())
    } catch (error: any) {
        yield handleError(error)
    }
}

export function* updatePaymentsAttributes(
    action: PayloadAction<IPaymentsResponseAttributes>
) {
    try {
        const token: string = yield select(selectCartToken)

        const response: ICartItemResponse = yield call(
            request,
            '/api/v2/storefront/checkout?include=line_items,variants,variants.images,billing_address,shipping_address,user,payments,shipments,promotions',
            {
                method: 'PATCH',
                data: action.payload,
                headers: {
                    'X-Spree-Order-Token': token,
                    'Content-Type': 'application/vnd.api+json',
                },
            }
        )

        yield put(cartActions.cartUpdated(response.data))
        yield put(checkoutActions.orderComplete())
    } catch (error: any) {
        yield handleError(error)
    }
}

export function* orderComplete() {
    try {
        const token: string = yield select(selectCartToken)

        const completeResponse: ICartItemResponse = yield call(
            request,
            '/api/v2/storefront/checkout/complete?include=line_items,variants,variants.images,billing_address,shipping_address,user,payments,shipments,promotions',
            {
                method: 'PATCH',
                headers: {
                    'X-Spree-Order-Token': token,
                    'Content-Type': 'application/vnd.api+json',
                },
            }
        )

        yield put(cartActions.cartUpdated(completeResponse.data))

        const payment = completeResponse.included.find(
            (item): item is IPayment => item.type === 'payment'
        )

        if (
            payment?.attributes.payment_method_id === 1 ||
            payment?.attributes.payment_method_id === 3 ||
            payment?.attributes.payment_method_id === 4
        ) {
            const createPaymentResponse: ICartItemResponse = yield call(
                request,
                '/api/v2/storefront/checkout/create_payment',
                {
                    method: 'POST',
                    headers: {
                        'X-Spree-Order-Token': token,
                        'Content-Type': 'application/vnd.api+json',
                    },
                }
            )

            yield put(cartActions.cartUpdated(createPaymentResponse.data))
        } else {
            yield put(
                cartActions.cartUpdated({
                    ...completeResponse.data,
                    attributes: {
                        ...completeResponse.data.attributes,
                        payment_url: '/profile/orders',
                    },
                })
            )
        }
    } catch (error: any) {
        yield handleError(error)
    }
}

export function* partnerOrderComplete(
    action: PayloadAction<IPartnerInformation>
) {
    try {
        const token: string = yield select(selectCartToken)

        const completeResponse: ICartItemResponse = yield call(
            request,
            '/api/v2/storefront/checkout/complete_wholesale?include=line_items,variants,variants.images,billing_address,shipping_address,user,payments,shipments,promotions',
            {
                method: 'PATCH',
                data: action.payload,
                headers: {
                    'X-Spree-Order-Token': token,
                    'Content-Type': 'application/vnd.api+json',
                },
            }
        )

        yield put(cartActions.cartUpdated(completeResponse.data))

        yield put(
            cartActions.cartUpdated({
                ...completeResponse.data,
                attributes: {
                    ...completeResponse.data.attributes,
                    payment_url: '/profile/orders',
                },
            })
        )
    } catch (error: any) {
        yield handleError(error)
    }
}

export function* checkoutWatcher() {
    yield takeLatest(
        checkoutActions.loadPaymentMethods.type,
        loadPaymentMethods
    )
    yield takeLatest(checkoutActions.loadCheckoutNext.type, checkoutNext)
    yield takeLatest(
        checkoutActions.loadCheckoutPrevious.type,
        checkoutPrevious
    )
    yield takeLatest(checkoutActions.applyBonuses.type, applyBonuses)
    yield takeLatest(checkoutActions.applyPromocode.type, applyPromocode)
    yield takeLatest(checkoutActions.deletePromocode.type, deletePromocode)
    yield takeLatest(
        checkoutActions.updateCustomerInformation.type,
        updateCustomerInformation
    )
    yield takeLatest(
        checkoutActions.confirmDeliveryMethod.type,
        confirmDeliveryMethod
    )
    yield takeLatest(
        checkoutActions.updatePaymentsAttributes.type,
        updatePaymentsAttributes
    )
    yield takeLatest(checkoutActions.orderComplete.type, orderComplete)
    yield takeLatest(
        checkoutActions.partnerOrderComplete.type,
        partnerOrderComplete
    )
}

import { FormGroup } from '@mui/material'
import React from 'react'

import { useCategoryPageContext } from '../../hooks'
import { ProductsFilterKind } from '../../ProductsFilterKind'
import { ProductsFilterFormControlLabel } from './ProductsFilterFormControlLabel'

export const ProductsFilterOnlyInStock: React.FC = () => {
    const { filterReadAdapter, handleAvailability } = useCategoryPageContext()

    return (
        <ProductsFilterKind name="Наличие">
            <FormGroup>
                <ProductsFilterFormControlLabel
                    key="availability"
                    labelText="Только в наличии"
                    checked={
                        filterReadAdapter.available_in_current_city || false
                    }
                    onChange={() => handleAvailability()}
                />
            </FormGroup>
        </ProductsFilterKind>
    )
}

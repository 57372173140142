import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Divider,
    Drawer,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { CloseIcon } from 'app/components/Icons/CloseIcon'
import { MOBILE_NAVIGATION_HEIGHT } from 'app/modules/Layout/components/Navigation/partials/MobileNavigation'
import React from 'react'

export interface ModalProps {
    open: boolean
    title: React.ReactNode
    height?: string
    handleClose: () => void
    closeIconColor?: string
    bottomButton?: React.ReactNode
}

export const ModalDialogSwicth: React.FC<ModalProps> = (props) => {
    const { open, title, handleClose, closeIconColor, children } = props

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    if (isMobile)
        return (
            <Modal height="auto" {...props}>
                {children}
            </Modal>
        )
    if (isDesktop) {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                BackdropProps={{
                    style: { backgroundColor: 'rgba(0, 0, 0, 0.3)' },
                }}
                PaperProps={{
                    sx: {
                        borderRadius: '28px',
                        maxWidth: '630px',
                    },
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '30px',
                        right: '30px',
                        zIndex: 1,
                    }}
                >
                    <IconButton onClick={handleClose} sx={{ p: '0px' }}>
                        <CloseIcon color={closeIconColor} />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        py: 4.25,
                        width: {
                            md: '100%',
                        },
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    {typeof title === 'string' ? (
                        <Typography
                            sx={{
                                pt: 2,
                                fontWeight: 500,
                                textTransform: 'uppercase',
                            }}
                            variant="h5"
                            textAlign={'center'}
                        >
                            {title}
                        </Typography>
                    ) : (
                        <>{title}</>
                    )}

                    <DialogContent sx={{ p: 0 }}>{children}</DialogContent>
                </Box>
            </Dialog>
        )
    }
    return null
}

export const Modal: React.FC<ModalProps> = ({
    children,
    open,
    title,
    height,
    handleClose,
    closeIconColor,
    bottomButton,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const totalMobileHeight =
        height || `calc(100% - ${MOBILE_NAVIGATION_HEIGHT}px)`

    return (
        <Drawer
            open={open}
            anchor={isMobile ? 'bottom' : 'right'}
            BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.3)' } }}
            PaperProps={{
                sx: {
                    width: '100%',
                    height: { xs: totalMobileHeight, md: '100%' },
                    borderTopLeftRadius: { xs: '20px', md: '0px' },
                    borderTopRightRadius: { xs: '20px', md: '0px' },
                    maxWidth: { xs: '100%', md: 590 },
                    overflowX: { xs: 'hidden', md: 'auto' },
                },
            }}
            onClose={handleClose}
            sx={{
                zIndex: 1400,
            }}
        >
            <Box
                sx={{
                    pt: '34px',
                    pb: '30px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: { xs: totalMobileHeight, md: '100%' },
                }}
            >
                {typeof title === 'string' ? (
                    <Typography
                        sx={{
                            fontWeight: 500,
                            textTransform: 'uppercase',
                        }}
                        variant="h5"
                        ml={{ xs: '16px', md: '30px' }}
                    >
                        {title}
                    </Typography>
                ) : (
                    <>{title}</>
                )}

                <Box
                    sx={{
                        position: 'absolute',
                        top: { xs: '34px', md: '30px' },
                        right: { xs: '18px', md: '30px' },
                        zIndex: 1,
                    }}
                >
                    <IconButton onClick={handleClose} sx={{ p: '0px' }}>
                        <CloseIcon color={closeIconColor} />
                    </IconButton>
                </Box>
                {Boolean(bottomButton) && (
                    <Box
                        sx={{
                            position: 'fixed',
                            bottom: '16px',
                            // right: { xs: '18px', md: '30px' },
                            zIndex: 1,
                            width: '100%',
                            px: '16px',
                        }}
                    >
                        {typeof bottomButton === 'string' ? (
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{ height: '60px' }}
                                onClick={handleClose}
                            >
                                {bottomButton}
                            </Button>
                        ) : (
                            <>{bottomButton}</>
                        )}
                    </Box>
                )}
                <Box
                    sx={{
                        position: 'absolute',
                        top: '8px',
                        width: '100%',
                        zIndex: 1,
                        display: { md: 'none' },
                    }}
                >
                    <Box
                        width="40px"
                        height="4px"
                        mx="auto"
                        sx={{
                            borderRadius: '10px',
                            backgroundColor: '#DEDEDE',
                        }}
                    />
                </Box>

                <Divider sx={{ mt: { xs: '34px', md: '22px' } }} />

                {children}
            </Box>
        </Drawer>
    )
}

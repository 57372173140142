import {
    Box,
    Divider,
    Skeleton,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { selectAccountWholesale } from 'app/modules/Account/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { ICart } from 'types/ICart'

interface OrderDetailsProps {
    order?: ICart
}

export const OrderDetails: React.FC<OrderDetailsProps> = ({ order }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))

    const mindbox_data = order ? order.attributes.mindbox_data : null
    const wholesale = useSelector(selectAccountWholesale)

    return (
        <>
            {!isMobile && (
                <>
                    <Box
                        px={{
                            xs: 2,
                            md: 6.25,
                        }}
                        py={5}
                    >
                        {order ? (
                            <Stack spacing={2.5}>
                                <Stack
                                    justifyContent="space-between"
                                    direction="row"
                                >
                                    <Typography variant="body2">
                                        Товары ({order?.attributes.item_count})
                                    </Typography>

                                    <Typography
                                        variant="body2"
                                        fontWeight="medium"
                                    >
                                        {order?.attributes.display_item_total}
                                    </Typography>
                                </Stack>

                                {!wholesale && (
                                    <>
                                        <Stack
                                            justifyContent="space-between"
                                            direction="row"
                                        >
                                            <Typography variant="body2">
                                                Полная скидка
                                            </Typography>

                                            <Typography
                                                color="error"
                                                variant="body2"
                                                fontWeight="medium"
                                            >
                                                - {
                                                    mindbox_data?.items_discount
                                                }
                                            </Typography>
                                        </Stack>

                                        {!!mindbox_data?.spent_bonuses && <Stack
                                            justifyContent="space-between"
                                            direction="row"
                                        >
                                            <Typography variant="body2">
                                                Оплата бонусными рублями
                                            </Typography>

                                            <Typography
                                                color="error"
                                                variant="body2"
                                                fontWeight="medium"
                                            >
                                                - {mindbox_data?.spent_bonuses}
                                            </Typography>
                                        </Stack>
                                        }

                                        <Stack
                                            justifyContent="space-between"
                                            direction="row"
                                        >
                                            <Typography variant="body2">
                                                Стоимость доставки
                                            </Typography>

                                            <Typography
                                                color="primary"
                                                variant="body2"
                                                fontWeight="medium"
                                            >
                                                {Number(order.attributes.ship_total)
                                                    ? order.attributes.display_ship_total
                                                    : 'Бесплатно'}
                                            </Typography>
                                        </Stack>

                                        {!!mindbox_data?.earned_bonuses && <Stack
                                            justifyContent="space-between"
                                            direction="row"
                                        >
                                            <Typography variant="body2">
                                                Начисляемые бонусы
                                            </Typography>

                                            <Typography
                                                color="primary"
                                                variant="body2"
                                                fontWeight="medium"
                                            >
                                                {mindbox_data?.earned_bonuses}
                                            </Typography>
                                        </Stack>
                                        }
                                    </>
                                )}
                            </Stack>
                        ) : (
                            <Skeleton />
                        )}
                    </Box>

                    <Divider />
                </>
            )}

            <Box
                px={{
                    xs: 2,
                    md: 6.25,
                }}
                py={5}
            >
                {order ? (
                    <Stack spacing={2.5}>
                        <Stack justifyContent="space-between" direction="row">
                            <Typography variant="body2">Итого</Typography>

                            <Typography
                                sx={(theme) => ({
                                    typography: {
                                        xs: {
                                            ...theme.typography.body1,
                                            fontWeight: 500,
                                        },
                                        md: {
                                            ...theme.typography.h5,
                                            fontWeight: 500,
                                        },
                                    },
                                })}
                            >
                                {order?.attributes.display_total}
                            </Typography>
                        </Stack>
                    </Stack>
                ) : (
                    <Skeleton width="100%" />
                )}
            </Box>
        </>
    )
}

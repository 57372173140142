import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { accountActions } from 'app/modules/Account/slice'
import { cartActions } from 'app/modules/Cart/slice'
import { selectCart, selectCartState } from 'app/modules/Cart/slice/selectors'
import { checkoutActions } from 'app/modules/Checkout/slice'
import { DESKTOP_HEADER_HEIGHT } from 'app/modules/Layout/components/Header/DesktopHeader'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CustomerCreateOrderSteps } from '../../components/CustomerCreateOrderSteps'
import { OrderDetail } from '../../components/OrderDetail/OrderDetail'

export const CustomerCreateOrder: React.FC = () => {
    const dispatch = useDispatch()

    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

    const cart = useSelector(selectCart)
    const cartState = useSelector(selectCartState)

    useEffect(function loadInitialInformation() {
        dispatch(accountActions.getLoyalty())
        dispatch(cartActions.getCartShop())
    }, [])

    useEffect(
        function redirectToPaymentUrl() {
            if (cart?.attributes.payment_url) {
                dispatch(cartActions.deleteCartToken())
                dispatch(cartActions.deleteCartState())
                dispatch(cartActions.createCart())
                dispatch(
                    cartActions.redirectToPaymentUrl({
                        payment_url: cart.attributes.payment_url,
                    })
                )
            }
        },
        [cart?.attributes.payment_url]
    )

    useEffect(
        function skipFirstCartState() {
            if (cartState === 'cart' && cart?.attributes.item_count !== 0)
                dispatch(checkoutActions.loadCheckoutNext())
        },
        [cartState]
    )

    if (!cart) {
        return null
    }

    return (
        <Grid container sx={{ height: '100%' }}>
            <Grid item lg={7}>
                <Box
                    sx={{
                        position: { xs: 'static', md: 'sticky' },
                        top: { xs: 'auto', md: DESKTOP_HEADER_HEIGHT },
                    }}
                >
                    <CustomerCreateOrderSteps />
                </Box>
            </Grid>

            {isDesktop && (
                <Grid
                    item
                    lg={5}
                    sx={{
                        px: '100px',
                        backgroundColor: '#F5F3F3',
                    }}
                >
                    <OrderDetail cart={cart} />
                </Grid>
            )}
        </Grid>
    )
}

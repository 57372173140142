import { Box } from '@mui/material'
import { bannersActions } from 'app/modules/Banners/slice'
import { selectMainBanners } from 'app/modules/Banners/slice/selectors'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { BannerContent } from '../BannerContent'

export const MainBanner: React.FC = () => {
    const dispatch = useDispatch()

    const mainBanners = useSelector(selectMainBanners)
    const mainBanner = mainBanners.find(Boolean)

    useEffect(
        function LoadMainBanners() {
            if (mainBanners.length == 0)
                dispatch(bannersActions.loadMainBanner())
        },
        [mainBanners.length]
    )

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                minHeight: { xs: '100vw', md: 'unset' },
                maxHeight: { xs: '120vw', md: '39.5vw' },
            }}
        >
            {mainBanner && <BannerContent content={mainBanner} />}
        </Box>
    )
}

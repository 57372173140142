import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'types'

import { shopsAdapter } from '.'

const { selectAll } = shopsAdapter.getSelectors()

const selectDomain = (state: RootState) => state.shops

export const selectShopByCityId = createSelector(
    [selectDomain],
    (state) => (id?: string) => {
        return selectAll(state).filter(
            (shop) => !id || shop.relationships.city.data?.id === id
        )
    }
)

import { Box } from '@mui/material'
import { selectMainBanners } from 'app/modules/Banners/slice/selectors'
import React from 'react'
import { useSelector } from 'react-redux'

import { BannerContent } from '../BannerContent'

export const FamilyBanner: React.FC = () => {
    const mainBanners = useSelector(selectMainBanners)
    const familyBanner = mainBanners[1]

    return (
        <Box
            sx={{
                width: '100%',
                maxHeight: '44vw',
            }}
        >
            {familyBanner && <BannerContent content={familyBanner} />}
        </Box>
    )
}
